import {LoginRequest} from '../types/request/LoginRequest';
import {has} from 'ramda';
import {useCallback, useContext} from 'react';
import {AuthenticationContext} from './AuthenticationContext';
import {usePostLogin} from '../api/client';

export const useLogin = () => {
  const {setToken, setIsLoggedIn, setUser} = useContext(AuthenticationContext);
  const {mutate, error, isLoading} = usePostLogin();

  const login = useCallback((options: LoginRequest) => {
    mutate({
      data: {
        email: options.email,
        password: options.password,
      },
    }, {
      onSuccess: (data) => {
        if (has('token', data) && data.user && data.user) {
          setToken(data.token);
          setUser(data.user);
          setIsLoggedIn(true);
        }
      },
    });
  }, [mutate]);

  return {
    isLoading,
    error,
    login,
  };
}
