import {PropsWithChildren, useState} from 'react';
import {ValidationError} from '../api/validation/ValidationError';
import {ApplicationError} from '../error/ApplicationError';
import {NestedComponentErrorContext} from './NestedComponentErrorContext';

export const AppForm = (props: PropsWithChildren) => {
  const [error, setError] = useState<undefined | ApplicationError | ValidationError>(undefined);

  return <NestedComponentErrorContext.Provider value={{error: error, setError}}>
    {props.children}
  </NestedComponentErrorContext.Provider>
}