import {ErrorCode} from './ErrorCode';

export const errorMessages: Record<ErrorCode, string> = {
  [ErrorCode.UNKNOWN]: 'An unknown error occurred',
  [ErrorCode.NETWORK_ERROR]: 'A network error occurred',
  [ErrorCode.VALIDATION_ERROR]: 'A validation error occurred',
  [ErrorCode.UNAUTHENTICATED]: 'Invalid username / password',
  [ErrorCode.EMPTY_INPUT_VALIDATION]: 'Please enter a value',
  [ErrorCode.EMPTY_SELECT_VALIDATION]: 'Please select an option',
  [ErrorCode.INVALID_BULK_UPLOAD]:
      'Invalid bulk upload content, if you need assistance with uploading data please contact us on {{supportEmail}}',
  [ErrorCode.LOGGED_OUT]: 'You have been logged out',
  [ErrorCode.INVALID_PERMISSIONS]: 'You do not have permission to perform this action',
}