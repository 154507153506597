/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Special Procedures API
 * Special Procedures API
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  LogoutActiveUser200,
  GetLicence200,
  GetLicenceParams,
  GetLicenceTypes200,
  GetLicenceTypesParams,
  GetProcedureTypes200,
  GetProcedureTypesParams,
  GetLocalAuthorities200,
  GetLocalAuthoritiesParams,
  PostLogin200,
  ApplicationError,
  PostLoginBody,
  PostBulkUploadBody,
  GetOrganisations200,
  GetOrganisationsParams
} from './client.schemas'
import { destructor } from './mutator/destructor';
import type { ErrorType } from './mutator/destructor';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Logout
 */
export const logoutActiveUser = (
    
 options?: SecondParameter<typeof destructor>,) => {
      return destructor<LogoutActiveUser200>(
      {url: `/logout`, method: 'post'
    },
      options);
    }
  


export const getLogoutActiveUserMutationOptions = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof logoutActiveUser>>, TError,TVariables, TContext>, request?: SecondParameter<typeof destructor>}
): UseMutationOptions<Awaited<ReturnType<typeof logoutActiveUser>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof logoutActiveUser>>, TVariables> = () => {
          

          return  logoutActiveUser(requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type LogoutActiveUserMutationResult = NonNullable<Awaited<ReturnType<typeof logoutActiveUser>>>
    
    export type LogoutActiveUserMutationError = ErrorType<unknown>

    /**
 * @summary Logout
 */
export const useLogoutActiveUser = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof logoutActiveUser>>, TError,TVariables, TContext>, request?: SecondParameter<typeof destructor>}
) => {
    
      const mutationOptions = getLogoutActiveUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get licence
 */
export const getLicence = (
    params?: GetLicenceParams,
 options?: SecondParameter<typeof destructor>,signal?: AbortSignal
) => {
      return destructor<GetLicence200>(
      {url: `/licence`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetLicenceQueryKey = (params?: GetLicenceParams,) => [`/licence`, ...(params ? [params]: [])] as const;
  

    
export const getGetLicenceQueryOptions = <TData = Awaited<ReturnType<typeof getLicence>>, TError = ErrorType<unknown>>(params?: GetLicenceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLicence>>, TError, TData>, request?: SecondParameter<typeof destructor>}
): UseQueryOptions<Awaited<ReturnType<typeof getLicence>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLicenceQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLicence>>> = ({ signal }) => getLicence(params, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetLicenceQueryResult = NonNullable<Awaited<ReturnType<typeof getLicence>>>
export type GetLicenceQueryError = ErrorType<unknown>

/**
 * @summary Get licence
 */
export const useGetLicence = <TData = Awaited<ReturnType<typeof getLicence>>, TError = ErrorType<unknown>>(
 params?: GetLicenceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLicence>>, TError, TData>, request?: SecondParameter<typeof destructor>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLicenceQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get all licence types
 */
export const getLicenceTypes = (
    params?: GetLicenceTypesParams,
 options?: SecondParameter<typeof destructor>,signal?: AbortSignal
) => {
      return destructor<GetLicenceTypes200>(
      {url: `/licence-types`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetLicenceTypesQueryKey = (params?: GetLicenceTypesParams,) => [`/licence-types`, ...(params ? [params]: [])] as const;
  

    
export const getGetLicenceTypesQueryOptions = <TData = Awaited<ReturnType<typeof getLicenceTypes>>, TError = ErrorType<unknown>>(params?: GetLicenceTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLicenceTypes>>, TError, TData>, request?: SecondParameter<typeof destructor>}
): UseQueryOptions<Awaited<ReturnType<typeof getLicenceTypes>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLicenceTypesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLicenceTypes>>> = ({ signal }) => getLicenceTypes(params, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetLicenceTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getLicenceTypes>>>
export type GetLicenceTypesQueryError = ErrorType<unknown>

/**
 * @summary Get all licence types
 */
export const useGetLicenceTypes = <TData = Awaited<ReturnType<typeof getLicenceTypes>>, TError = ErrorType<unknown>>(
 params?: GetLicenceTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLicenceTypes>>, TError, TData>, request?: SecondParameter<typeof destructor>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLicenceTypesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get all procedure types
 */
export const getProcedureTypes = (
    params?: GetProcedureTypesParams,
 options?: SecondParameter<typeof destructor>,signal?: AbortSignal
) => {
      return destructor<GetProcedureTypes200>(
      {url: `/procedure-types`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetProcedureTypesQueryKey = (params?: GetProcedureTypesParams,) => [`/procedure-types`, ...(params ? [params]: [])] as const;
  

    
export const getGetProcedureTypesQueryOptions = <TData = Awaited<ReturnType<typeof getProcedureTypes>>, TError = ErrorType<unknown>>(params?: GetProcedureTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProcedureTypes>>, TError, TData>, request?: SecondParameter<typeof destructor>}
): UseQueryOptions<Awaited<ReturnType<typeof getProcedureTypes>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProcedureTypesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProcedureTypes>>> = ({ signal }) => getProcedureTypes(params, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetProcedureTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getProcedureTypes>>>
export type GetProcedureTypesQueryError = ErrorType<unknown>

/**
 * @summary Get all procedure types
 */
export const useGetProcedureTypes = <TData = Awaited<ReturnType<typeof getProcedureTypes>>, TError = ErrorType<unknown>>(
 params?: GetProcedureTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProcedureTypes>>, TError, TData>, request?: SecondParameter<typeof destructor>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProcedureTypesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get all local authorities
 */
export const getLocalAuthorities = (
    params?: GetLocalAuthoritiesParams,
 options?: SecondParameter<typeof destructor>,signal?: AbortSignal
) => {
      return destructor<GetLocalAuthorities200>(
      {url: `/local-authorities`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetLocalAuthoritiesQueryKey = (params?: GetLocalAuthoritiesParams,) => [`/local-authorities`, ...(params ? [params]: [])] as const;
  

    
export const getGetLocalAuthoritiesQueryOptions = <TData = Awaited<ReturnType<typeof getLocalAuthorities>>, TError = ErrorType<unknown>>(params?: GetLocalAuthoritiesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLocalAuthorities>>, TError, TData>, request?: SecondParameter<typeof destructor>}
): UseQueryOptions<Awaited<ReturnType<typeof getLocalAuthorities>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLocalAuthoritiesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLocalAuthorities>>> = ({ signal }) => getLocalAuthorities(params, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetLocalAuthoritiesQueryResult = NonNullable<Awaited<ReturnType<typeof getLocalAuthorities>>>
export type GetLocalAuthoritiesQueryError = ErrorType<unknown>

/**
 * @summary Get all local authorities
 */
export const useGetLocalAuthorities = <TData = Awaited<ReturnType<typeof getLocalAuthorities>>, TError = ErrorType<unknown>>(
 params?: GetLocalAuthoritiesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLocalAuthorities>>, TError, TData>, request?: SecondParameter<typeof destructor>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLocalAuthoritiesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Login
 */
export const postLogin = (
    postLoginBody: PostLoginBody,
 options?: SecondParameter<typeof destructor>,) => {
      return destructor<PostLogin200>(
      {url: `/login`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postLoginBody
    },
      options);
    }
  


export const getPostLoginMutationOptions = <TError = ErrorType<ApplicationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postLogin>>, TError,{data: PostLoginBody}, TContext>, request?: SecondParameter<typeof destructor>}
): UseMutationOptions<Awaited<ReturnType<typeof postLogin>>, TError,{data: PostLoginBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postLogin>>, {data: PostLoginBody}> = (props) => {
          const {data} = props ?? {};

          return  postLogin(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postLogin>>>
    export type PostLoginMutationBody = PostLoginBody
    export type PostLoginMutationError = ErrorType<ApplicationError>

    /**
 * @summary Login
 */
export const usePostLogin = <TError = ErrorType<ApplicationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postLogin>>, TError,{data: PostLoginBody}, TContext>, request?: SecondParameter<typeof destructor>}
) => {
    
      const mutationOptions = getPostLoginMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Bulk Upload Licenses
 */
export const postBulkUpload = (
    postBulkUploadBody: PostBulkUploadBody,
 options?: SecondParameter<typeof destructor>,) => {
      return destructor<void>(
      {url: `/bulk-upload`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postBulkUploadBody
    },
      options);
    }
  


export const getPostBulkUploadMutationOptions = <TError = ErrorType<ApplicationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postBulkUpload>>, TError,{data: PostBulkUploadBody}, TContext>, request?: SecondParameter<typeof destructor>}
): UseMutationOptions<Awaited<ReturnType<typeof postBulkUpload>>, TError,{data: PostBulkUploadBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postBulkUpload>>, {data: PostBulkUploadBody}> = (props) => {
          const {data} = props ?? {};

          return  postBulkUpload(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostBulkUploadMutationResult = NonNullable<Awaited<ReturnType<typeof postBulkUpload>>>
    export type PostBulkUploadMutationBody = PostBulkUploadBody
    export type PostBulkUploadMutationError = ErrorType<ApplicationError>

    /**
 * @summary Bulk Upload Licenses
 */
export const usePostBulkUpload = <TError = ErrorType<ApplicationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postBulkUpload>>, TError,{data: PostBulkUploadBody}, TContext>, request?: SecondParameter<typeof destructor>}
) => {
    
      const mutationOptions = getPostBulkUploadMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get all organisations, paginated
 */
export const getOrganisations = (
    params?: GetOrganisationsParams,
 options?: SecondParameter<typeof destructor>,signal?: AbortSignal
) => {
      return destructor<GetOrganisations200>(
      {url: `/organisations`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOrganisationsQueryKey = (params?: GetOrganisationsParams,) => [`/organisations`, ...(params ? [params]: [])] as const;
  

    
export const getGetOrganisationsQueryOptions = <TData = Awaited<ReturnType<typeof getOrganisations>>, TError = ErrorType<ApplicationError>>(params?: GetOrganisationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganisations>>, TError, TData>, request?: SecondParameter<typeof destructor>}
): UseQueryOptions<Awaited<ReturnType<typeof getOrganisations>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganisationsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganisations>>> = ({ signal }) => getOrganisations(params, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetOrganisationsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganisations>>>
export type GetOrganisationsQueryError = ErrorType<ApplicationError>

/**
 * @summary Get all organisations, paginated
 */
export const useGetOrganisations = <TData = Awaited<ReturnType<typeof getOrganisations>>, TError = ErrorType<ApplicationError>>(
 params?: GetOrganisationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganisations>>, TError, TData>, request?: SecondParameter<typeof destructor>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganisationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


