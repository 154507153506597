import {Button, FormControl, FormErrorMessage, FormLabel, Input, Link, InputGroup, InputRightElement, VStack} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {extractValidationError} from '../api/validation/extractValidationErrors';
import {useAppForm} from '../hooks/useAppForm';
import {EyeOffIcon} from '../icons/eyeOffIcon';
import {EyeIcon} from '../icons/eyeIcon';
import {useEffect, useState} from 'react';
import {formErrorMessageStyles} from '../theme/FormErrorMessageStyles';
import {Spacing08} from '@RHEnvironmental/pattern-library-tokens';
import {px} from '../theme/px';
import {useLogin} from './useLogin';

interface LoginFormFields {
  email: string;
  password: string;
}

const EMAIL_VALIDATION_MESSAGE = 'Enter a valid email address';
const PASSWORD_VALIDATION_MESSAGE = 'Enter a valid password';

export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    setError: rhFormSetError,
    clearErrors
  } = useForm<LoginFormFields>();

  const {t} = useTranslation();
  const {login, error, isLoading} = useLogin();
  const normalError = error ?? undefined;
  const validationError = extractValidationError(normalError);
  const {setError} = useAppForm({error: normalError});

  useEffect(() => {
    setError(validationError);
    if (validationError?.existsForField('email')) {
      rhFormSetError('email', {
        type: 'focus',
        message: t<string>(EMAIL_VALIDATION_MESSAGE)
      }, {shouldFocus: true});
    }
    if (validationError?.existsForField('password')) {
      rhFormSetError('password', {
        type: 'focus',
        message: t<string>(PASSWORD_VALIDATION_MESSAGE)
      }, {shouldFocus: true});
    }
  }, [validationError])

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (data: LoginFormFields) => {
    clearErrors();
    login(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={px(Spacing08)} align="stretch">
        <FormControl isInvalid={!!errors.email}>
          <FormLabel htmlFor={'login-form-email'} fontWeight="700">
            <Trans>Email</Trans>
          </FormLabel>
          <Input
            {...register('email', {
              required: t<string>(EMAIL_VALIDATION_MESSAGE),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t<string>(EMAIL_VALIDATION_MESSAGE),
              },
            })}
            id={'login-form-email'}
            type="email"
            data-testid={'login-form__email-field'}
            placeholder="Enter email"
          />
          <FormErrorMessage sx={formErrorMessageStyles}>
            {errors.email && <p data-testid="login-form__email-error">{errors.email.message}</p>}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.password}>
          <FormLabel variant={'primary'} htmlFor={'login-form-password'} fontWeight="700">
            <Trans>Password</Trans>
          </FormLabel>
          <InputGroup>
            <Input
              {...register('password', {
                required: t<string>(PASSWORD_VALIDATION_MESSAGE),
              })}
              id={'login-form-password'}
              type={showPassword ? 'text' : 'password'}
              data-testid={'login-form__password-field'}
              placeholder="Enter password"
            />
            <InputRightElement onClick={togglePasswordVisibility} children={
              <button
                type="button"
                aria-label={showPassword ? 'Hide password' : 'Show password'}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <EyeIcon /> : <EyeOffIcon />}
              </button> } />
          </InputGroup>
          <FormErrorMessage sx={formErrorMessageStyles}>
            {errors.password && <p data-testid="login-form__password-error">{errors.password.message}</p>}
          </FormErrorMessage>
          <Link href={process.env.RESET_PASSWORD_URL}>
            <Trans>Forgotten password?</Trans>
          </Link>
        </FormControl>
        <Button
          type="submit"
          variant={'primary'}
          data-testid={'login-form__submit-button'}
          isLoading={isLoading}
          mt="50px"
          w="100%"
        >
          <Trans>Sign in</Trans>
        </Button>
      </VStack>
    </form>
  );
};
