/**
 * Do not edit directly
 * Generated on Wed, 01 Feb 2023 16:56:55 GMT
 */

export const TextWhite100 = "#fafafa";
export const TextLight500 = "#6e6764";
export const TextMedium600 = "#5e5856";
export const TextDark800 = "#403b39";
export const TextBlack800 = "#1a1a1a";
export const TextPrimary = "#c23300";
export const TextError = "#c30909";
export const TextLink = "#2d5ab4";
export const TextLinkHover = "#284687";
export const TextInformation = "#2471f5";
export const TextSuccess = "#08a141";
export const TextWarning = "#f5ba31";
export const TextHeadingLight1FontFamily = "Helvetica Neue";
export const TextHeadingLight1FontWeight = 300;
export const TextHeadingLight1LineHeight = 64;
export const TextHeadingLight1FontSize = 48;
export const TextHeadingLight1LetterSpacing = "0%";
export const TextHeadingLight1ParagraphSpacing = 0;
export const TextHeadingLight1ParagraphIndent = 0;
export const TextHeadingLight1TextCase = "none";
export const TextHeadingLight1TextDecoration = "none";
export const TextHeadingLight2FontFamily = "Helvetica Neue";
export const TextHeadingLight2FontWeight = 300;
export const TextHeadingLight2LineHeight = 60;
export const TextHeadingLight2FontSize = 40;
export const TextHeadingLight2LetterSpacing = "0%";
export const TextHeadingLight2ParagraphSpacing = 0;
export const TextHeadingLight2ParagraphIndent = 0;
export const TextHeadingLight2TextCase = "none";
export const TextHeadingLight2TextDecoration = "none";
export const TextHeadingLight3FontFamily = "Helvetica Neue";
export const TextHeadingLight3FontWeight = 300;
export const TextHeadingLight3LineHeight = 48;
export const TextHeadingLight3FontSize = 32;
export const TextHeadingLight3LetterSpacing = "0%";
export const TextHeadingLight3ParagraphSpacing = 0;
export const TextHeadingLight3ParagraphIndent = 0;
export const TextHeadingLight3TextCase = "none";
export const TextHeadingLight3TextDecoration = "none";
export const TextHeadingLight4FontFamily = "Helvetica Neue";
export const TextHeadingLight4FontWeight = 300;
export const TextHeadingLight4LineHeight = 32;
export const TextHeadingLight4FontSize = 24;
export const TextHeadingLight4LetterSpacing = "0%";
export const TextHeadingLight4ParagraphSpacing = 0;
export const TextHeadingLight4ParagraphIndent = 0;
export const TextHeadingLight4TextCase = "none";
export const TextHeadingLight4TextDecoration = "none";
export const TextHeadingLight5FontFamily = "Helvetica Neue";
export const TextHeadingLight5FontWeight = 300;
export const TextHeadingLight5LineHeight = 28;
export const TextHeadingLight5FontSize = 18;
export const TextHeadingLight5LetterSpacing = "0%";
export const TextHeadingLight5ParagraphSpacing = 0;
export const TextHeadingLight5ParagraphIndent = 0;
export const TextHeadingLight5TextCase = "none";
export const TextHeadingLight5TextDecoration = "none";
export const TextHeadingLight6FontFamily = "Helvetica Neue";
export const TextHeadingLight6FontWeight = 300;
export const TextHeadingLight6LineHeight = 24;
export const TextHeadingLight6FontSize = 16;
export const TextHeadingLight6LetterSpacing = "0%";
export const TextHeadingLight6ParagraphSpacing = 0;
export const TextHeadingLight6ParagraphIndent = 0;
export const TextHeadingLight6TextCase = "none";
export const TextHeadingLight6TextDecoration = "none";
export const TextHeadingMedium1FontFamily = "Helvetica Neue";
export const TextHeadingMedium1FontWeight = 500;
export const TextHeadingMedium1LineHeight = 64;
export const TextHeadingMedium1FontSize = 48;
export const TextHeadingMedium1LetterSpacing = "0%";
export const TextHeadingMedium1ParagraphSpacing = 0;
export const TextHeadingMedium1ParagraphIndent = 0;
export const TextHeadingMedium1TextCase = "none";
export const TextHeadingMedium1TextDecoration = "none";
export const TextHeadingMedium2FontFamily = "Helvetica Neue";
export const TextHeadingMedium2FontWeight = 500;
export const TextHeadingMedium2LineHeight = 60;
export const TextHeadingMedium2FontSize = 40;
export const TextHeadingMedium2LetterSpacing = "0%";
export const TextHeadingMedium2ParagraphSpacing = 0;
export const TextHeadingMedium2ParagraphIndent = 0;
export const TextHeadingMedium2TextCase = "none";
export const TextHeadingMedium2TextDecoration = "none";
export const TextHeadingMedium3FontFamily = "Helvetica Neue";
export const TextHeadingMedium3FontWeight = 500;
export const TextHeadingMedium3LineHeight = 48;
export const TextHeadingMedium3FontSize = 32;
export const TextHeadingMedium3LetterSpacing = "0%";
export const TextHeadingMedium3ParagraphSpacing = 0;
export const TextHeadingMedium3ParagraphIndent = 0;
export const TextHeadingMedium3TextCase = "none";
export const TextHeadingMedium3TextDecoration = "none";
export const TextHeadingMedium4FontFamily = "Helvetica Neue";
export const TextHeadingMedium4FontWeight = 500;
export const TextHeadingMedium4LineHeight = 32;
export const TextHeadingMedium4FontSize = 24;
export const TextHeadingMedium4LetterSpacing = "0%";
export const TextHeadingMedium4ParagraphSpacing = 0;
export const TextHeadingMedium4ParagraphIndent = 0;
export const TextHeadingMedium4TextCase = "none";
export const TextHeadingMedium4TextDecoration = "none";
export const TextHeadingMedium5FontFamily = "Helvetica Neue";
export const TextHeadingMedium5FontWeight = 500;
export const TextHeadingMedium5LineHeight = 28;
export const TextHeadingMedium5FontSize = 18;
export const TextHeadingMedium5LetterSpacing = "0%";
export const TextHeadingMedium5ParagraphSpacing = 0;
export const TextHeadingMedium5ParagraphIndent = 0;
export const TextHeadingMedium5TextCase = "none";
export const TextHeadingMedium5TextDecoration = "none";
export const TextHeadingMedium6FontFamily = "Helvetica Neue";
export const TextHeadingMedium6FontWeight = 500;
export const TextHeadingMedium6LineHeight = 24;
export const TextHeadingMedium6FontSize = 16;
export const TextHeadingMedium6LetterSpacing = "0%";
export const TextHeadingMedium6ParagraphSpacing = 0;
export const TextHeadingMedium6ParagraphIndent = 0;
export const TextHeadingMedium6TextCase = "none";
export const TextHeadingMedium6TextDecoration = "none";
export const TextBodyReg1FontFamily = "Helvetica Neue";
export const TextBodyReg1FontWeight = 400;
export const TextBodyReg1LineHeight = 32;
export const TextBodyReg1FontSize = 24;
export const TextBodyReg1LetterSpacing = "0%";
export const TextBodyReg1ParagraphSpacing = 0;
export const TextBodyReg1ParagraphIndent = 0;
export const TextBodyReg1TextCase = "none";
export const TextBodyReg1TextDecoration = "none";
export const TextBodyReg2FontFamily = "Helvetica Neue";
export const TextBodyReg2FontWeight = 400;
export const TextBodyReg2LineHeight = 28;
export const TextBodyReg2FontSize = 18;
export const TextBodyReg2LetterSpacing = "0%";
export const TextBodyReg2ParagraphSpacing = 0;
export const TextBodyReg2ParagraphIndent = 0;
export const TextBodyReg2TextCase = "none";
export const TextBodyReg2TextDecoration = "none";
export const TextBodyReg3FontFamily = "Helvetica Neue";
export const TextBodyReg3FontWeight = 400;
export const TextBodyReg3LineHeight = 24;
export const TextBodyReg3FontSize = 16;
export const TextBodyReg3LetterSpacing = "0%";
export const TextBodyReg3ParagraphSpacing = 0;
export const TextBodyReg3ParagraphIndent = 0;
export const TextBodyReg3TextCase = "none";
export const TextBodyReg3TextDecoration = "none";
export const TextBodyReg4FontFamily = "Helvetica Neue";
export const TextBodyReg4FontWeight = 400;
export const TextBodyReg4LineHeight = 20;
export const TextBodyReg4FontSize = 14;
export const TextBodyReg4LetterSpacing = "0%";
export const TextBodyReg4ParagraphSpacing = 0;
export const TextBodyReg4ParagraphIndent = 0;
export const TextBodyReg4TextCase = "none";
export const TextBodyReg4TextDecoration = "none";
export const TextBodyReg5FontFamily = "Helvetica Neue";
export const TextBodyReg5FontWeight = 400;
export const TextBodyReg5LineHeight = 18;
export const TextBodyReg5FontSize = 12;
export const TextBodyReg5LetterSpacing = "0%";
export const TextBodyReg5ParagraphSpacing = 0;
export const TextBodyReg5ParagraphIndent = 0;
export const TextBodyReg5TextCase = "none";
export const TextBodyReg5TextDecoration = "none";
export const TextBodyReg6FontFamily = "Helvetica Neue";
export const TextBodyReg6FontWeight = 400;
export const TextBodyReg6LineHeight = 16;
export const TextBodyReg6FontSize = 10;
export const TextBodyReg6LetterSpacing = "0%";
export const TextBodyReg6ParagraphSpacing = 0;
export const TextBodyReg6ParagraphIndent = 0;
export const TextBodyReg6TextCase = "none";
export const TextBodyReg6TextDecoration = "none";
export const TextBodyMedium1FontFamily = "Helvetica Neue";
export const TextBodyMedium1FontWeight = 500;
export const TextBodyMedium1LineHeight = 32;
export const TextBodyMedium1FontSize = 24;
export const TextBodyMedium1LetterSpacing = "0%";
export const TextBodyMedium1ParagraphSpacing = 0;
export const TextBodyMedium1ParagraphIndent = 0;
export const TextBodyMedium1TextCase = "none";
export const TextBodyMedium1TextDecoration = "none";
export const TextBodyMedium2FontFamily = "Helvetica Neue";
export const TextBodyMedium2FontWeight = 500;
export const TextBodyMedium2LineHeight = 28;
export const TextBodyMedium2FontSize = 18;
export const TextBodyMedium2LetterSpacing = "0%";
export const TextBodyMedium2ParagraphSpacing = 0;
export const TextBodyMedium2ParagraphIndent = 0;
export const TextBodyMedium2TextCase = "none";
export const TextBodyMedium2TextDecoration = "none";
export const TextBodyMedium3FontFamily = "Helvetica Neue";
export const TextBodyMedium3FontWeight = 500;
export const TextBodyMedium3LineHeight = 24;
export const TextBodyMedium3FontSize = 16;
export const TextBodyMedium3LetterSpacing = "0%";
export const TextBodyMedium3ParagraphSpacing = 0;
export const TextBodyMedium3ParagraphIndent = 0;
export const TextBodyMedium3TextCase = "none";
export const TextBodyMedium3TextDecoration = "none";
export const TextBodyMedium4FontFamily = "Helvetica Neue";
export const TextBodyMedium4FontWeight = 500;
export const TextBodyMedium4LineHeight = 20;
export const TextBodyMedium4FontSize = 14;
export const TextBodyMedium4LetterSpacing = "0%";
export const TextBodyMedium4ParagraphSpacing = 0;
export const TextBodyMedium4ParagraphIndent = 0;
export const TextBodyMedium4TextCase = "none";
export const TextBodyMedium4TextDecoration = "none";
export const TextBodyMedium5FontFamily = "Helvetica Neue";
export const TextBodyMedium5FontWeight = 500;
export const TextBodyMedium5LineHeight = 18;
export const TextBodyMedium5FontSize = 12;
export const TextBodyMedium5LetterSpacing = "0%";
export const TextBodyMedium5ParagraphSpacing = 0;
export const TextBodyMedium5ParagraphIndent = 0;
export const TextBodyMedium5TextCase = "none";
export const TextBodyMedium5TextDecoration = "none";
export const TextBodyMedium6FontFamily = "Helvetica Neue";
export const TextBodyMedium6FontWeight = 500;
export const TextBodyMedium6LineHeight = 16;
export const TextBodyMedium6FontSize = 10;
export const TextBodyMedium6LetterSpacing = "0%";
export const TextBodyMedium6ParagraphSpacing = 0;
export const TextBodyMedium6ParagraphIndent = 0;
export const TextBodyMedium6TextCase = "none";
export const TextBodyMedium6TextDecoration = "none";
export const TextBodyLink1FontFamily = "Helvetica Neue";
export const TextBodyLink1FontWeight = 400;
export const TextBodyLink1LineHeight = 32;
export const TextBodyLink1FontSize = 24;
export const TextBodyLink1LetterSpacing = "0%";
export const TextBodyLink1ParagraphSpacing = 0;
export const TextBodyLink1ParagraphIndent = 0;
export const TextBodyLink1TextCase = "none";
export const TextBodyLink1TextDecoration = "underline";
export const TextBodyLink2FontFamily = "Helvetica Neue";
export const TextBodyLink2FontWeight = 400;
export const TextBodyLink2LineHeight = 28;
export const TextBodyLink2FontSize = 18;
export const TextBodyLink2LetterSpacing = "0%";
export const TextBodyLink2ParagraphSpacing = 0;
export const TextBodyLink2ParagraphIndent = 0;
export const TextBodyLink2TextCase = "none";
export const TextBodyLink2TextDecoration = "underline";
export const TextBodyLink3FontFamily = "Helvetica Neue";
export const TextBodyLink3FontWeight = 400;
export const TextBodyLink3LineHeight = 24;
export const TextBodyLink3FontSize = 16;
export const TextBodyLink3LetterSpacing = "0%";
export const TextBodyLink3ParagraphSpacing = 0;
export const TextBodyLink3ParagraphIndent = 0;
export const TextBodyLink3TextCase = "none";
export const TextBodyLink3TextDecoration = "underline";
export const TextBodyLink4FontFamily = "Helvetica Neue";
export const TextBodyLink4FontWeight = 400;
export const TextBodyLink4LineHeight = 20;
export const TextBodyLink4FontSize = 14;
export const TextBodyLink4LetterSpacing = "0%";
export const TextBodyLink4ParagraphSpacing = 0;
export const TextBodyLink4ParagraphIndent = 0;
export const TextBodyLink4TextCase = "none";
export const TextBodyLink4TextDecoration = "underline";
export const TextBodyLink5FontFamily = "Helvetica Neue";
export const TextBodyLink5FontWeight = 400;
export const TextBodyLink5LineHeight = 18;
export const TextBodyLink5FontSize = 12;
export const TextBodyLink5LetterSpacing = "0%";
export const TextBodyLink5ParagraphSpacing = 0;
export const TextBodyLink5ParagraphIndent = 0;
export const TextBodyLink5TextCase = "none";
export const TextBodyLink5TextDecoration = "underline";
export const TextBodyLink6FontFamily = "Helvetica Neue";
export const TextBodyLink6FontWeight = 400;
export const TextBodyLink6LineHeight = 16;
export const TextBodyLink6FontSize = 10;
export const TextBodyLink6LetterSpacing = "0%";
export const TextBodyLink6ParagraphSpacing = 0;
export const TextBodyLink6ParagraphIndent = 0;
export const TextBodyLink6TextCase = "none";
export const TextBodyLink6TextDecoration = "underline";
export const TextButton1FontFamily = "Helvetica Neue";
export const TextButton1FontWeight = 500;
export const TextButton1LineHeight = 28;
export const TextButton1FontSize = 18;
export const TextButton1LetterSpacing = "0%";
export const TextButton1ParagraphSpacing = 0;
export const TextButton1ParagraphIndent = 0;
export const TextButton1TextCase = "none";
export const TextButton1TextDecoration = "none";
export const TextButton2FontFamily = "Helvetica Neue";
export const TextButton2FontWeight = 500;
export const TextButton2LineHeight = 24;
export const TextButton2FontSize = 16;
export const TextButton2LetterSpacing = "0%";
export const TextButton2ParagraphSpacing = 0;
export const TextButton2ParagraphIndent = 0;
export const TextButton2TextCase = "none";
export const TextButton2TextDecoration = "none";
export const TextButton3FontFamily = "Helvetica Neue";
export const TextButton3FontWeight = 500;
export const TextButton3LineHeight = 20;
export const TextButton3FontSize = 14;
export const TextButton3LetterSpacing = "0%";
export const TextButton3ParagraphSpacing = 0;
export const TextButton3ParagraphIndent = 0;
export const TextButton3TextCase = "none";
export const TextButton3TextDecoration = "none";
export const IconWhite100 = "#fafafa";
export const IconLight500 = "#6e6764";
export const IconMedium600 = "#5e5856";
export const IconDark800 = "#403b39";
export const IconBlack800 = "#1a1a1a";
export const IconPrimary = "#c23300";
export const IconPrimaryDark = "#751f00";
export const IconError = "#c30909";
export const IconWarning = "#f5ba31";
export const IconInfomation = "#2471f5";
export const IconSuccess = "#08a141";
export const BorderWhite100 = "#fafafa";
export const BorderLight200 = "#cac6c5";
export const BorderLight500 = "#6e6764";
export const BorderMedium600 = "#5e5856";
export const BorderDark800 = "#403b39";
export const BorderBlack800 = "#1a1a1a";
export const BorderError = "#c30909";
export const BorderPrimary = "#c23300";
export const BorderFocus = "#2d5ab4";
export const BorderInformation = "#2471f5";
export const BorderSuccess = "#08a141";
export const BorderWarning = "#f5ba31";
export const Layer01 = "#fafafa";
export const Layer02 = "#f5f5f5";
export const Layer03 = "#ededed";
export const Layer04 = "#e6e6e6";
export const CanvasErrorLight = "#f3ebe8";
export const CanvasWarningLight = "#f3efe8";
export const CanvasInformationLight = "#dbe0eb";
export const CanvasSuccessLight = "#e8f3ec";
export const CanvasTableHeader = "#fafafa";
export const CanvasTableRowDark = "#e6e6e6";
export const CanvasTableRowLight = "#f5f5f5";
export const CanvasPrimary = "#c23300";
export const CanvasInformation = "#2471f5";
export const CanvasSuccess = "#08a141";
export const CanvasWarning = "#f5ba31";
export const CanvasError = "#c30909";
export const CanvasSelectionGrey = "#e6e6e6";
export const CanvasDarkGrey = "#4f4a48";
export const CanvasTableRowUnread = "#e6e8f5";
export const ButtonPrimaryBgFill = "#c23300";
export const ButtonPrimaryBgFillHover = "#a82d00";
export const ButtonPrimaryBgFillPressed = "#8f2400";
export const ButtonPrimaryText = "#fafafa";
export const ButtonPrimaryTextHover = "#cac6c5";
export const ButtonPrimaryTextPressed = "#ada6a4";
export const ButtonPrimaryIcon = "#fafafa";
export const ButtonPrimaryIconHover = "#cac6c5";
export const ButtonPrimaryIconPressed = "#ada6a4";
export const ButtonPrimaryDisableFill = "#ada6a4";
export const ButtonSecondaryBgFill = "#fafafa";
export const ButtonSecondaryBgFillHover = "#f5f5f5";
export const ButtonSecondaryBgFillPressed = "#ededed";
export const ButtonSecondaryBorder = "#c23300";
export const ButtonSecondaryBorderHover = "#a82d00";
export const ButtonSecondaryBorderPressed = "#8f2400";
export const ButtonSecondaryText = "#c23300";
export const ButtonSecondaryIcon = "#c23300";
export const ButtonSecondaryTextHover = "#a82d00";
export const ButtonSecondaryIconHover = "#a82d00";
export const ButtonSecondaryTextPressed = "#8f2400";
export const ButtonSecondaryIconPressed = "#8f2400";
export const ButtonTertiaryButtonTertiaryBorder = "#c23300";
export const ButtonTertiaryButtonTertiaryText = "#c23300";
export const ButtonTertiaryButtonTertiaryIcon = "#c23300";
export const ButtonTertiaryButtonTertiaryBorderHover = "#a82d00";
export const ButtonTertiaryButtonTertiaryTextHover = "#a82d00";
export const ButtonTertiaryButtonTertiaryIconHover = "#a82d00";
export const ButtonTertiaryButtonTertiaryBorderPressed = "#8f2400";
export const ButtonTertiaryButtonTertiaryTextPressed = "#8f2400";
export const ButtonTertiaryButtonTertiaryIconPressed = "#8f2400";
export const ButtonGhostText = "#c23300";
export const ButtonGhostTextHover = "#a82d00";
export const ButtonGhostTextPressed = "#8f2400";
export const ButtonGhostIcon = "#c23300";
export const ButtonGhostIconHover = "#a82d00";
export const ButtonGhostIconPressed = "#8f2400";
export const PrimaryNavigationWebBgFillUnselected = "#c23300";
export const PrimaryNavigationWebBgFillHover = "#a82d00";
export const PrimaryNavigationWebBgFillSelected = "#f5f5f5";
export const PrimaryNavigationWebIconSelected = "#403b39";
export const PrimaryNavigationWebTextSelected = "#403b39";
export const PrimaryNavigationWebIconUnselected = "#fafafa";
export const PrimaryNavigationWebTextUnselected = "#fafafa";
export const SecondaryNavigationUnselected = "#f5f5f5";
export const SecondaryNavigationHover = "#ededed";
export const SecondaryNavigationSelected = "#e6e6e6";
export const SecondaryNavigationSelectedBar = "#c23300";
export const SecondaryNavigationUnderline = "#cac6c5";
export const TopHeaderBackground = "#fafafa";
export const TopHeaderIcons = "#403b39";
export const TogglesStroke = "#5e5856";
export const TogglesSelected = "#751f00";
export const TogglesFill = "#fafafa";
export const TogglesIcon = "#fafafa";
export const TogglesToggleOff = "#ada6a4";
export const Box2DownLightX = 0;
export const Box2DownLightY = 2;
export const Box2DownLightBlur = 4;
export const Box2DownLightSpread = 0;
export const Box2DownLightColor = "#1a1a1a33";
export const Box2DownLightType = "dropShadow";
export const Box4DownLightX = 0;
export const Box4DownLightY = 4;
export const Box4DownLightBlur = 6;
export const Box4DownLightSpread = 0;
export const Box4DownLightColor = "#1a1a1a33";
export const Box4DownLightType = "dropShadow";
export const Box2UpLightX = 0;
export const Box2UpLightY = -2;
export const Box2UpLightBlur = 4;
export const Box2UpLightSpread = 0;
export const Box2UpLightColor = "#1a1a1a33";
export const Box2UpLightType = "dropShadow";
export const Box4UpLightCopyX = 0;
export const Box4UpLightCopyY = -4;
export const Box4UpLightCopyBlur = 6;
export const Box4UpLightCopySpread = 0;
export const Box4UpLightCopyColor = "#1a1a1a33";
export const Box4UpLightCopyType = "dropShadow";
export const Box2LeftLightX = -2;
export const Box2LeftLightY = 0;
export const Box2LeftLightBlur = 4;
export const Box2LeftLightSpread = 0;
export const Box2LeftLightColor = "#1a1a1a33";
export const Box2LeftLightType = "dropShadow";
export const Box4LeftLightX = -4;
export const Box4LeftLightY = 0;
export const Box4LeftLightBlur = 6;
export const Box4LeftLightSpread = 0;
export const Box4LeftLightColor = "#1a1a1a33";
export const Box4LeftLightType = "dropShadow";
export const Box2RightLightX = 2;
export const Box2RightLightY = 0;
export const Box2RightLightBlur = 4;
export const Box2RightLightSpread = 0;
export const Box2RightLightColor = "#1a1a1a33";
export const Box2RightLightType = "dropShadow";
export const Box4RightLightX = 4;
export const Box4RightLightY = 0;
export const Box4RightLightBlur = 6;
export const Box4RightLightSpread = 0;
export const Box4RightLightColor = "#1a1a1a33";
export const Box4RightLightType = "dropShadow";
export const Box2CenterLightX = 0;
export const Box2CenterLightY = 0;
export const Box2CenterLightBlur = 4;
export const Box2CenterLightSpread = 0;
export const Box2CenterLightColor = "#1a1a1a33";
export const Box2CenterLightType = "dropShadow";
export const Box4CenterLightX = 0;
export const Box4CenterLightY = 0;
export const Box4CenterLightBlur = 6;
export const Box4CenterLightSpread = 0;
export const Box4CenterLightColor = "#1a1a1a33";
export const Box4CenterLightType = "dropShadow";
export const Box2DownMediumX = 0;
export const Box2DownMediumY = 2;
export const Box2DownMediumBlur = 4;
export const Box2DownMediumSpread = 0;
export const Box2DownMediumColor = "#1a1a1a66";
export const Box2DownMediumType = "dropShadow";
export const Box4DownMediumX = 0;
export const Box4DownMediumY = 4;
export const Box4DownMediumBlur = 6;
export const Box4DownMediumSpread = 0;
export const Box4DownMediumColor = "#1a1a1a66";
export const Box4DownMediumType = "dropShadow";
export const Box2LeftMediumX = -2;
export const Box2LeftMediumY = 0;
export const Box2LeftMediumBlur = 4;
export const Box2LeftMediumSpread = 0;
export const Box2LeftMediumColor = "#1a1a1a66";
export const Box2LeftMediumType = "dropShadow";
export const Box4LeftMediumX = -4;
export const Box4LeftMediumY = 0;
export const Box4LeftMediumBlur = 6;
export const Box4LeftMediumSpread = 0;
export const Box4LeftMediumColor = "#1a1a1a66";
export const Box4LeftMediumType = "dropShadow";
export const Box2RightMediumX = 2;
export const Box2RightMediumY = 0;
export const Box2RightMediumBlur = 4;
export const Box2RightMediumSpread = 0;
export const Box2RightMediumColor = "#1a1a1a66";
export const Box2RightMediumType = "dropShadow";
export const Box4RightMediumX = 4;
export const Box4RightMediumY = 0;
export const Box4RightMediumBlur = 6;
export const Box4RightMediumSpread = 0;
export const Box4RightMediumColor = "#1a1a1a66";
export const Box4RightMediumType = "dropShadow";
export const Box2UpMediumX = 0;
export const Box2UpMediumY = -2;
export const Box2UpMediumBlur = 4;
export const Box2UpMediumSpread = 0;
export const Box2UpMediumColor = "#1a1a1a66";
export const Box2UpMediumType = "dropShadow";
export const Box4UpMediumX = 0;
export const Box4UpMediumY = -4;
export const Box4UpMediumBlur = 6;
export const Box4UpMediumSpread = 0;
export const Box4UpMediumColor = "#1a1a1a66";
export const Box4UpMediumType = "dropShadow";
export const Box2CenterMediumX = 0;
export const Box2CenterMediumY = 0;
export const Box2CenterMediumBlur = 4;
export const Box2CenterMediumSpread = 0;
export const Box2CenterMediumColor = "#1a1a1a66";
export const Box2CenterMediumType = "dropShadow";
export const Box4CenterMediumX = 0;
export const Box4CenterMediumY = 0;
export const Box4CenterMediumBlur = 6;
export const Box4CenterMediumSpread = 0;
export const Box4CenterMediumColor = "#1a1a1a66";
export const Box4CenterMediumType = "dropShadow";
export const Box2DownDarkX = 0;
export const Box2DownDarkY = 2;
export const Box2DownDarkBlur = 4;
export const Box2DownDarkSpread = 0;
export const Box2DownDarkColor = "#1a1a1a99";
export const Box2DownDarkType = "dropShadow";
export const Box4DownDarkX = 0;
export const Box4DownDarkY = 4;
export const Box4DownDarkBlur = 6;
export const Box4DownDarkSpread = 0;
export const Box4DownDarkColor = "#1a1a1a99";
export const Box4DownDarkType = "dropShadow";
export const Box2UpDarkX = 0;
export const Box2UpDarkY = -2;
export const Box2UpDarkBlur = 4;
export const Box2UpDarkSpread = 0;
export const Box2UpDarkColor = "#1a1a1a99";
export const Box2UpDarkType = "dropShadow";
export const Box4UpDarkX = 0;
export const Box4UpDarkY = -4;
export const Box4UpDarkBlur = 6;
export const Box4UpDarkSpread = 0;
export const Box4UpDarkColor = "#1a1a1a99";
export const Box4UpDarkType = "dropShadow";
export const Box2LeftDarkX = -2;
export const Box2LeftDarkY = 0;
export const Box2LeftDarkBlur = 4;
export const Box2LeftDarkSpread = 0;
export const Box2LeftDarkColor = "#1a1a1a99";
export const Box2LeftDarkType = "dropShadow";
export const Box4LeftDarkX = -4;
export const Box4LeftDarkY = 0;
export const Box4LeftDarkBlur = 6;
export const Box4LeftDarkSpread = 0;
export const Box4LeftDarkColor = "#1a1a1a99";
export const Box4LeftDarkType = "dropShadow";
export const Box2RightDarkX = 2;
export const Box2RightDarkY = 0;
export const Box2RightDarkBlur = 4;
export const Box2RightDarkSpread = 0;
export const Box2RightDarkColor = "#1a1a1a99";
export const Box2RightDarkType = "dropShadow";
export const Box4RightDarkX = 4;
export const Box4RightDarkY = 0;
export const Box4RightDarkBlur = 6;
export const Box4RightDarkSpread = 0;
export const Box4RightDarkColor = "#1a1a1a99";
export const Box4RightDarkType = "dropShadow";
export const Box2CenterDarkX = 0;
export const Box2CenterDarkY = 0;
export const Box2CenterDarkBlur = 4;
export const Box2CenterDarkSpread = 0;
export const Box2CenterDarkColor = "#1a1a1a99";
export const Box2CenterDarkType = "dropShadow";
export const Box4CenterDarkX = 0;
export const Box4CenterDarkY = 0;
export const Box4CenterDarkBlur = 6;
export const Box4CenterDarkSpread = 0;
export const Box4CenterDarkColor = "#1a1a1a99";
export const Box4CenterDarkType = "dropShadow";
export const PrimaryNavigationMobileBgFillUnselected = "#fafafa";
export const PrimaryNavigationMobileBgFillSelected = "#e6e6e6";
export const PrimaryNavigationMobileIconUnselected = "#5e5856";
export const PrimaryNavigationMobileIconSelected = "#403b39";
export const PrimaryNavigationMobileTextUnselected = "#5e5856";
export const PrimaryNavigationMobileTextSelected = "#403b39";
export const PrimaryNavigationMobileSelectedBar = "#c23300";
export const FontFamiliesHelveticaNeue = "Helvetica Neue";
export const LineHeights0 = 64;
export const LineHeights1 = 60;
export const LineHeights2 = 48;
export const LineHeights3 = 32;
export const LineHeights4 = 28;
export const LineHeights5 = 24;
export const LineHeights6 = 20;
export const LineHeights7 = 18;
export const LineHeights8 = 16;
export const FontWeightsHelveticaNeue0 = 300;
export const FontWeightsHelveticaNeue1 = 500;
export const FontWeightsHelveticaNeue2 = 400;
export const FontSize0 = 10;
export const FontSize1 = 12;
export const FontSize2 = 14;
export const FontSize4 = 18;
export const FontSize5 = 24;
export const FontSize6 = 32;
export const FontSize7 = 40;
export const FontSize8 = 48;
export const FontSize1rem = 16;
export const LetterSpacing0 = "0%";
export const ParagraphSpacing0 = 0;
export const TextCaseNone = "none";
export const TextDecorationNone = "none";
export const TextDecorationUnderline = "underline";
export const Base1100 = "#f3ebe8";
export const Base1200 = "#fa7342";
export const Base1300 = "#f95215";
export const Base1400 = "#db3a00";
export const Base1500 = "#c23300";
export const Base1600 = "#a82d00";
export const Base1700 = "#8f2400";
export const Base1800 = "#751f00";
export const Base2100 = "#e6e8f5";
export const Base2200 = "#c3c8e6";
export const Base2300 = "#8c9bcd";
export const Base2400 = "#5f77b9";
export const Base2500 = "#2d5ab4";
export const Base2600 = "#284687";
export const Base2700 = "#193264";
export const Base2800 = "#051441";
export const Neutral100 = "#e4e2e2";
export const Neutral200 = "#cac6c5";
export const Neutral300 = "#ada6a4";
export const Neutral400 = "#938b88";
export const Neutral500 = "#6e6764";
export const Neutral600 = "#5e5856";
export const Neutral700 = "#4f4a48";
export const Neutral800 = "#403b39";
export const Grey100 = "#fafafa";
export const Grey200 = "#f5f5f5";
export const Grey300 = "#ededed";
export const Grey400 = "#e6e6e6";
export const Grey500 = "#595959";
export const Grey600 = "#4d4d4d";
export const Grey700 = "#333333";
export const Grey800 = "#1a1a1a";
export const BorderRadius01 = 2;
export const BorderRadius02 = 4;
export const SuccessLight = "#e8f3ec";
export const SuccessDark = "#08a141";
export const InformationLight = "#dbe0eb";
export const InformationDark = "#2471f5";
export const ErrorLight = "#f3ebe8";
export const ErrorDark = "#c30909";
export const WarningLight = "#f3efe8";
export const WarningDark = "#f5ba31";
export const BorderRadius04 = 8;
export const BorderRadius08 = 16;
export const BorderWidth01 = 1;
export const BorderWidth02 = 2;
export const BorderWidth04 = 4;
export const BorderWidth08 = 8;
export const Opacity02 = "20%";
export const Opacity04 = "40%";
export const Opacity06 = "60%";
export const Opacity07 = "70%";
export const Opacity08 = "80%";
export const Opacity09 = "90%";
export const Spacing01 = 2;
export const Spacing02 = 4;
export const Spacing03 = 8;
export const Spacing04 = 12;
export const Spacing05 = 16;
export const Spacing06 = 24;
export const Spacing07 = 32;
export const Spacing08 = 40;
export const Shadow100 = "#1a1a1a1a";
export const Shadow200 = "#1a1a1a33";
export const Shadow300 = "#1a1a1a4d";
export const Shadow400 = "#1a1a1a66";
export const Shadow500 = "#1a1a1a80";
export const Shadow600 = "#1a1a1a99";
export const Shadow700 = "#1a1a1ab3";
export const Shadow800 = "#1a1a1acc";
export const Shadow900 = "#1a1a1ae6";
export const TokenSetOrder0 = "global";
export const TokenSetOrder1 = "Light-mode";
export const TnaLightMode = "[object Object]";
export const TnaDarkMode = "[object Object]";