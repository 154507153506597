import {Dispatch, SetStateAction, createContext} from 'react';

interface ICreateLicenceContext {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}

export const CreateLicenceContext = createContext<ICreateLicenceContext>({
  step: 1,
  setStep: () => {},
})