import {FormControl, FormErrorMessage, FormLabel} from '@chakra-ui/react';
import {Trans} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import {extractError} from '../../error/extractError';
import {formErrorMessageStyles} from '../../theme/FormErrorMessageStyles';
import {GenericInputType} from '../../types/validation/Input';
import {ReactNode} from 'react';

interface InputTypeComponent extends GenericInputType {
  label: string;
  children: ReactNode;
}

export const InputWrapper = ({
  id = '',
  required = false,
  label = '',
  children,
}: InputTypeComponent) => {
  const {
    formState: {errors},
  } = useFormContext();

  const errorMessage = extractError(errors, id);

  return (
    <FormControl isInvalid={(!!errorMessage && required)}>
      <FormLabel>
        <Trans>{label}</Trans>
      </FormLabel>
      {children}
      {!!errorMessage &&
        <FormErrorMessage sx={formErrorMessageStyles}>
          <Trans>{errorMessage}</Trans>
        </FormErrorMessage>
      }
    </FormControl>
  )
}