import {
  TextBodyReg2FontFamily,
  TextBodyReg2FontWeight,
  TextBodyReg2FontSize,
  TextBodyReg2LetterSpacing,
  TextBodyReg2TextDecoration,
  TextBodyReg2TextCase,
  TextBodyReg2LineHeight,
  TextBodyReg3FontFamily,
  TextBodyReg3FontWeight,
  TextBodyReg3FontSize,
  TextBodyReg3LetterSpacing,
  TextBodyReg3TextDecoration,
  TextBodyReg3TextCase,
  TextBodyReg3LineHeight,
  TextHeadingMedium4FontFamily,
  TextHeadingMedium4FontWeight,
  TextHeadingMedium4FontSize,
  TextHeadingMedium4LetterSpacing,
  TextHeadingMedium4TextDecoration,
  TextHeadingMedium4LineHeight,
  TextHeadingMedium4TextCase,
  TextHeadingMedium2FontFamily,
  TextHeadingMedium2FontWeight,
  TextHeadingMedium2FontSize,
  TextHeadingMedium2LetterSpacing,
  TextHeadingMedium2TextDecoration,
  TextHeadingMedium2LineHeight,
  TextHeadingMedium2TextCase,
  TextHeadingLight1FontFamily,
  TextHeadingLight1FontWeight,
  TextHeadingLight1FontSize,
  TextHeadingLight1LetterSpacing,
  TextHeadingLight1TextDecoration,
  TextHeadingLight1LineHeight,
  TextHeadingLight1TextCase,
  TextHeadingLight4FontFamily,
  TextHeadingLight4FontWeight,
  TextHeadingLight4FontSize,
  TextHeadingLight4LetterSpacing,
  TextHeadingLight4TextDecoration,
  TextHeadingLight4LineHeight,
  TextHeadingLight4TextCase,
  TextBodyMedium4FontFamily,
  TextBodyMedium4FontSize,
  TextBodyMedium4FontWeight,
  TextBodyMedium4LetterSpacing,
  TextBodyMedium4LineHeight,
  TextBodyMedium4TextCase,
  TextBodyMedium4TextDecoration,
  TextBodyLink4FontFamily,
  TextBodyLink4TextDecoration,
  TextBodyLink4TextCase,
  TextBodyLink4FontSize,
  TextBodyLink4LineHeight,
  TextBodyLink4FontWeight,
  TextBodyReg4FontFamily,
  TextBodyReg4FontWeight,
  TextBodyReg4FontSize,
  TextBodyReg4LetterSpacing,
  TextBodyReg4LineHeight,
  TextBodyReg4TextCase,
  TextBodyReg4TextDecoration,
  TextBodyMedium3FontFamily,
  TextBodyMedium3FontSize,
  TextBodyMedium3FontWeight,
  TextBodyMedium3LetterSpacing,
  TextBodyMedium3LineHeight,
  TextBodyMedium3TextCase,
  TextBodyMedium3TextDecoration,
  TextButton3FontFamily,
  TextButton3FontWeight,
  TextButton3FontSize,
  TextButton3LineHeight,
  TextButton3LetterSpacing,
  TextButton3TextDecoration,
  TextButton2FontFamily,
  TextButton2FontSize,
  TextButton2FontWeight,
  TextButton2LetterSpacing,
  TextButton2LineHeight,
  TextButton2TextDecoration,
} from '@RHEnvironmental/pattern-library-tokens';
import {px} from './px';
import {FallbackFonts} from './fallbackFonts';

export const textStyles = {
  'headingLt4': {
    fontFamily: TextHeadingLight4FontFamily+FallbackFonts,
    fontWeight: TextHeadingLight4FontWeight,
    fontSize: px(TextHeadingLight4FontSize),
    letterSpacing: TextHeadingLight4LetterSpacing,
    textDecoration: TextHeadingLight4TextDecoration,
    lineHeight: px(TextHeadingLight4LineHeight),
    textTransform: TextHeadingLight4TextCase,
  },
  'headingLtPrimary': {
    fontFamily: TextHeadingLight1FontFamily+FallbackFonts,
    fontWeight: px(TextHeadingLight1FontWeight),
    fontSize: px(TextHeadingLight1FontSize),
    letterSpacing: TextHeadingLight1LetterSpacing,
    textDecoration: TextHeadingLight1TextDecoration,
    lineHeight: px(TextHeadingLight1LineHeight),
    textTransform: TextHeadingLight1TextCase,
    color: 'primary.text',
  },
  'headingMd4Primary': {
    fontFamily: TextHeadingMedium4FontFamily+FallbackFonts,
    fontWeight: px(TextHeadingMedium4FontWeight),
    fontSize: px(TextHeadingMedium4FontSize),
    letterSpacing: TextHeadingMedium4LetterSpacing,
    textDecoration: TextHeadingMedium4TextDecoration,
    lineHeight: px(TextHeadingMedium4LineHeight),
    textTransform: TextHeadingMedium4TextCase,
    color: 'primary.text',
  },
  'headingMd2Primary': {
    fontFamily: TextHeadingMedium2FontFamily+FallbackFonts,
    fontWeight: px(TextHeadingMedium2FontWeight),
    fontSize: px(TextHeadingMedium2FontSize),
    letterSpacing: TextHeadingMedium2LetterSpacing,
    textDecoration: TextHeadingMedium2TextDecoration,
    lineHeight: px(TextHeadingMedium2LineHeight),
    textTransform: TextHeadingMedium2TextCase,
    color: 'primary.text',
  },
  'text03': {
    fontFamily: TextBodyReg3FontFamily+FallbackFonts,
    fontWeight: px(TextBodyReg3FontWeight),
    fontSize: px(TextBodyReg3FontSize),
    letterSpacing: TextBodyReg3LetterSpacing,
    textDecoration: TextBodyReg3TextDecoration,
    textTransform: TextBodyReg3TextCase,
    lineHeight: px(TextBodyReg3LineHeight),
  },
  'textBodyLink4': {
    fontFamily: TextBodyLink4FontFamily+FallbackFonts,
    fontWeight: TextBodyLink4FontWeight,
    lineHeight: px(TextBodyLink4LineHeight),
    fontSize: px(TextBodyLink4FontSize),
    letterSpacing: TextBodyMedium4LetterSpacing,
    textTransform: TextBodyLink4TextCase,
    textDecoration: TextBodyLink4TextDecoration,
  },
  'textBodyMedium3': {
    fontFamily: TextBodyMedium3FontFamily+FallbackFonts,
    fontWeight: TextBodyMedium3FontWeight,
    lineHeight: px(TextBodyMedium3LineHeight),
    letterSpacing: TextBodyMedium3LetterSpacing,
    textTransform: TextBodyMedium3TextCase,
    textDecoration: TextBodyMedium3TextDecoration,
    fontSize: px(TextBodyMedium3FontSize),
  },
  'textBodyMedium4': {
    fontFamily: TextBodyMedium4FontFamily+FallbackFonts,
    fontWeight: TextBodyMedium4FontWeight,
    lineHeight: px(TextBodyMedium4LineHeight),
    letterSpacing: TextBodyMedium4LetterSpacing,
    textTransform: TextBodyMedium4TextCase,
    textDecoration: TextBodyMedium4TextDecoration,
    fontSize: px(TextBodyMedium4FontSize),
  },
  'textBodyRegular2': {
    fontFamily: TextBodyReg2FontFamily + FallbackFonts,
    fontWeight: TextBodyReg2FontWeight,
    lineHeight: px(TextBodyReg2LineHeight),
    letterSpacing: TextBodyReg2LetterSpacing,
    textTransform: TextBodyReg2TextCase,
    textDecoration: TextBodyReg2TextDecoration,
    fontSize: px(TextBodyReg2FontSize),
  },
  'textBodyRegular3': {
    fontFamily: TextBodyReg3FontFamily + FallbackFonts,
    fontWeight: TextBodyReg3FontWeight,
    lineHeight: px(TextBodyReg3LineHeight),
    letterSpacing: TextBodyReg3LetterSpacing,
    textTransform: TextBodyReg3TextCase,
    textDecoration: TextBodyReg3TextDecoration,
    fontSize: px(TextBodyReg3FontSize),
  },
  'textBodyRegular4': {
    fontFamily: TextBodyReg4FontFamily+FallbackFonts,
    fontWeight: TextBodyReg4FontWeight,
    lineHeight: px(TextBodyReg4LineHeight),
    letterSpacing: TextBodyReg4LetterSpacing,
    textTransform: TextBodyReg4TextCase,
    textDecoration: TextBodyReg4TextDecoration,
    fontSize: px(TextBodyReg4FontSize),
  },
  'textButton2': {
    fontFamily: TextButton2FontFamily + FallbackFonts,
    fontWeight: TextButton2FontWeight,
    fontSize: px(TextButton2FontSize),
    lineHeight: px(TextButton2LineHeight),
    letterSpacing: TextButton2LetterSpacing,
    textDecoration: TextButton2TextDecoration,
  },
  'textButton3': {
    fontFamily: TextButton3FontFamily+FallbackFonts,
    fontWeight: TextButton3FontWeight,
    fontSize: px(TextButton3FontSize),
    lineHeight: px(TextButton3LineHeight),
    letterSpacing: TextButton3LetterSpacing,
    textDecoration: TextButton3TextDecoration,
  }
};