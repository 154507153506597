import {useState} from 'react';
import {Outlet} from 'react-router-dom';
import {SearchContext} from './SearchContext';

export const SearchContextProvider = () => {
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useState<boolean>(false);

  return <SearchContext.Provider value={{isSearchPanelOpen, setIsSearchPanelOpen}}>
    <Outlet />
  </SearchContext.Provider>
}