import {Address, CreateLicenceType} from '../types';

const DEFAULT_ADDRESS: Address = {
  'associated_business_name': '',
  'address_line1': '',
  'address_line2': '',
  'town': '',
  'postcode': '',
};

export const DEFAULT_VALUES: CreateLicenceType = {
  'issue_date': '',
  'licence_number': '',
  'licence_type_id': '',
  'procedure_type_ids': [],
  'internal_notes': '',
  'local_authority_id': 'abertawe',
  'practitioner_name': null,
  'addresses': [DEFAULT_ADDRESS],
  'business_name': null,
  'certificate_holder_name': null,
  'number_of_workstations': null,
  'vehicle_registration_details': null,
  'vehicle_description': null,
}

export const OPTIONAL_VALUES = {
  'local_authority_id': 'abertawe',
  'practitioner_name': null,
  'addresses': [{
    'associated_business_name': undefined,
    'address_line1': undefined,
    'address_line2': undefined,
    'town': undefined,
    'postcode': undefined,
  }],
  'business_name': null,
  'certificate_holder_name': null,
  'number_of_workstations': null,
  'vehicle_registration_details': null,
  'vehicle_description': null,
}