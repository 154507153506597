import {useState} from 'react';
import {CreateLicenceContext} from './CreateLicenceContext';
import {Outlet} from 'react-router-dom';
import {CreateLicenceScreens} from '../../types/CreateLicence';

export const CreateLicenceContextProvider = () => {
  const [step, setStep] = useState<number>(CreateLicenceScreens.BASIC_DETAILS);

  return <CreateLicenceContext.Provider value={{step, setStep}}>
    <Outlet />
  </CreateLicenceContext.Provider>

}