import {Checkbox, CheckboxGroup, Spinner, FormControl, FormLabel, VStack} from '@chakra-ui/react';
import {Trans, useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import {SpecificInputType} from '../../types/validation/Input';
import {InputWrapper} from './InputWrapper';
import {useGetProcedureTypes} from '../../api/client';
import {ProcedureTypeName} from '../../api/client.schemas';
import {useLanguage} from '../../hooks/useLanguage';

export const SelectProcedureTypes = ({id = 'procedureTypes', required = false, enabled = true}:SpecificInputType) => {
  const {t} = useTranslation();
  const language = useLanguage();
  const {register, getValues} = useFormContext();

  const {data: procedureTypes, isLoading} = useGetProcedureTypes({
    language,
  }, {
    query: {
      enabled,
    }
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (!procedureTypes) {
    return null;
  }

  return (
    <InputWrapper id={id} label="" required={required}>
      <FormControl as="fieldset">
        <FormLabel as="legend">
          Procedure types
        </FormLabel>
        <CheckboxGroup defaultValue={getValues(id)}>
          <VStack align="start">
            {procedureTypes.results.map(({name, procedure_type_id}: ProcedureTypeName, index: number) => (
              <Checkbox
                key={index}
                {...register(id, {
                  required: (required ? t<string>('Please select the procedure types for your organisation') : false),
                })}
                value={procedure_type_id}
              >
                <Trans>{name}</Trans>
              </Checkbox>
            ))}
          </VStack>
        </CheckboxGroup>
      </FormControl>
    </InputWrapper>
  )
}