import {Layer01, BorderWidth02, Box2DownLightX, Box2DownLightY, Box2DownLightBlur, Box2DownLightSpread, Box2DownLightColor} from '@RHEnvironmental/pattern-library-tokens';
import {figmaColors} from './colors';

export const HEADER_HEIGHT = '66px';

export const headerStyle = {
  position: 'sticky',
  top: '0',
  width: '100%',
  height: HEADER_HEIGHT,
  gridTemplateColumns: 'auto 1fr auto',
  padding: '12px',
  placeItems: 'center',
  gap: '12px',
  backgroundColor: Layer01,
  borderColor: figmaColors['base1-800'],
  borderBottomWidth: BorderWidth02,
  boxShadow: `${Box2DownLightX}px ${Box2DownLightY}px ${Box2DownLightBlur}px ${Box2DownLightSpread}px ${Box2DownLightColor}`,
  zIndex: 3,
}

export const headings = {
  justifySelf: 'start',
  justifyItems: 'center',
}