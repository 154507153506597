{
  "Skip to content": "Skip to content",
  "SPECIAL PROCEDURES": "SPECIAL PROCEDURES",
  "PUBLIC REGISTER": "PUBLIC REGISTER",
  "Search Results": "Search Results",
  "No results found": "No results found",
  "Close": "Close",
  "Search for business or practitioner name": "Search for business or practitioner name",
  "Filter": "Filter",
  "Local authority": "Local authority",
  "Select local authority": "Select local authority",
  "Procedure type": "Procedure type",
  "Select a licence": "Select a licence",
  "Search": "Search",
  "Tattooing": "Tattooing",
  "Body piercing": "Body piercing",
  "Acupuncture": "Acupuncture",
  "Electrolysis": "Electrolysis",
  "Semi-permanent makeup": "Semi-permanent makeup",
  "Dry needling": "Dry needling",
  "Certificate holder name": "Certificate holder name",
  "Approved premises address": "Approved premises address",
  "Number of workstations": "Number of workstations",
  "Show more": "Show more",
  "Associated business": "Associated business",
  "Apply": "Apply",
  "Sign in": "Sign in",
  "Sign out": "Sign out",
  "Email": "Email",
  "Password": "Password",
  "Forgotten password?": "Forgotten password?",
  "Show archive": "Show archive",
  "Issue date": "Issue date",
  "Bulk Upload": "Bulk Upload",
  "Continue": "Continue",
  "Enter practitioner name": "Enter practitioner name",
  "Practitioner name": "Practitioner name",
  "Address line 2": "Address line 2",
  "Enter town / city here": "Enter town / city here",
  "Enter address line 2 here": "Enter address line 2 here",
  "Address line 1": "Address line 1",
  "Town / City": "Town / City",
  "Enter associated business name here": "Enter associated business name here",
  "Associated business name": "Associated business name",
  "Enter postcode here": "Enter postcode here",
  "Enter address line 1 here": "Enter address line 1 here",
  "Add another business": "Add another business",
  "Internal notes (optional)": "Internal notes (optional)",
  "Back": "Back",
  "Save": "Save",
  "Postcode": "Postcode",
  "Licence successfully created": "Licence successfully created",
  "Premises": "Premises",
  "Practitioner": "Practitioner",
  "Vehicle": "Vehicle",
  "Create Single": "Create Single",
  "Submit": "Submit",
  "Success": "Success",
  "Bulk upload successful": "Bulk upload successful",
  "Error": "Error",
  "Invalid bulk upload content, if you need assistance with uploading data please contact us on {{supportEmail}}": "Invalid bulk upload content, if you need assistance with uploading data please contact us on {{supportEmail}}",
  "You have been logged out": "You have been logged out",
  "Invalid username / password": "Invalid username / password",
  "There is a problem": "There is a problem",
  "A network error occurred": "A network error occurred",
  "Licence type": "Licence type",
  "Status": "Status",
  "Show Less": "Show Less",
  "paginationSummary": "Page {{page}} of {{totalPages}}",
  "dateSuffixedArchived": "Archived - {{date}}",
  "dateSuffixedApproved": "Approved - {{date}}",
  "Enter a valid email address": "Enter a valid email address",
  "Enter a valid password": "Enter a valid password",
  "Please select an option": "Please select an option",
  "Please enter a value": "Please enter a value",
  "Enter a certificate holder name here": "Enter a certificate holder name here",
  "Enter business name here": "Enter business name here",
  "Enter number of workstations here": "Enter number of workstations here",
  "Business name": "Business name",
  "Please enter a valid number": "Please enter a valid number",
  "Licence could not be created": "Licence could not be created",
  "Unknown error, please try again later": "Unknown error, please try again later",
  "smarter": "smarter",
  "public": "public",
  "protection": "protection",
  "Help": "Help",
  "Accessibility": "Accessibility",
  "Contact": "Contact",
  "Legal": "Legal",
  "Copyright": "Copyright",
  "Privacy Policy": "Privacy Policy",
  "Terms Conditions": "Terms and Conditions",
  "RH Environmental Limited trading as RHE Global. All rights reserved.": "RH Environmental Limited trading as RHE Global. All rights reserved.",
  "Please select a licence type": "Please select a licence type",
  "Please enter the licence number": "Please enter the licence number",
  "site-intro": "All new Special Procedures Licenses and Approved Premises / Vehicles Certificates under this scheme will start to appear on this Register from 29th November 2024. There is a transitional period of 9 months during which time existing registered businesses can continue to operate however these will not show on this register until a new licence has been approved.",
  "Further details can be found": "Further details can be found",
  "here": "here",
  "Let's get started": "Let's get started"
}
