import {Box, Button, Heading, SimpleGrid, VStack} from '@chakra-ui/react';
import {Trans} from 'react-i18next';
import {CreateLicenceContext} from './context/CreateLicenceContext';
import {ReactNode, useContext} from 'react';
import {CreateLicenceScreens} from '../types/CreateLicence';
import {useFormContext} from 'react-hook-form';
import {CreateLicenceType} from '../types';
import {createLicenceStyle} from '../theme/CreateLicenceStyle';
import {TextDark800} from '@RHEnvironmental/pattern-library-tokens';
import {UploadIcon} from '../images/UploadIcon';
import {Link} from 'react-router-dom';
import {usePermissions} from '../admin/permissions/usePermissions';
import {PERMISSION_LICENCE_BULK_CREATE} from '../admin/permissions/Permissions';

interface CreateLicenceStepLayoutProps {
  header: string;
  children: ReactNode;
  onSubmit: (data:Partial<CreateLicenceType>) => void;
}

export const CreateLicenceStepLayout = ({header, children, onSubmit}:CreateLicenceStepLayoutProps) => {
  const {step, setStep} = useContext(CreateLicenceContext);
  const {handleSubmit, formState: {isSubmitting}} = useFormContext();
  const {hasPermission} = usePermissions();

  const goBack = () => {
    setStep(CreateLicenceScreens.BASIC_DETAILS);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{height: '100%'}}>
      <VStack sx={createLicenceStyle}>
        <Heading variant="Lt4" alignSelf='start' color={TextDark800}>{header}</Heading>
        {children}
        <SimpleGrid
          gridTemplateColumns='repeat(2, auto)'
          justifyContent="space-between"
          alignItems="end"
          width={'100%'}
        >
          <Box>
            {step > CreateLicenceScreens.BASIC_DETAILS &&
              <Button variant="secondary" onClick={goBack}>
                <Trans>Back</Trans>
              </Button>
            }
            {(step == CreateLicenceScreens.BASIC_DETAILS && hasPermission(PERMISSION_LICENCE_BULK_CREATE)) &&
                <Link to={'/bulk-upload'}>
                  <Button variant={'secondary'}>
                    <UploadIcon />&nbsp;<Trans>Bulk Upload</Trans>
                  </Button>
                </Link>
            }
          </Box>
          <Button type="submit" isDisabled={isSubmitting}>
            <Trans>{(step === CreateLicenceScreens.ADDITIONAL_DETAILS) ? 'Save and add another licence' : 'Continue'}</Trans>
          </Button>
        </SimpleGrid>
      </VStack>
    </form>
  )
}