import {InputsAddress} from '../../components/Inputs/InputsAddress';
import {SPInput} from '../../components/Inputs/SPInput';
import {SPTextarea} from '../../components/Inputs/SPTextarea';
import {LicenceSubmissionWrapper} from './LicenceSubmissionWrapper';

export const CertificatePremisesDetails = () => {
  return (
    <LicenceSubmissionWrapper header="Enter certificate premises details">
      <SPInput
        id='certificate_holder_name'
        required={true}
        label='Certificate holder name'
        placeholder='Enter a certificate holder name here'
        errors={{
          required: 'Please enter the certificate holder name'
        }}
      />
      <SPInput
        id='number_of_workstations'
        required={true}
        label='Number of workstations'
        placeholder='Enter number of workstations here'
        errors={{
          required: 'Please enter the number of workstations',
          pattern: {
            value: /^[0-9]+$/,
            message: 'Please enter a valid number'
          }
        }}
      />
      <InputsAddress
        isAssociated={false}
      />
      <SPTextarea
        id='internal_notes'
        label='Internal notes (optional)'
      />
    </LicenceSubmissionWrapper>
  )
}