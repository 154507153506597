import {
  BorderFocus,
  BorderLight500,
  BorderWidth01,
  BorderWidth02,
  TextBlack800,
  TextLight500,
  BorderError,
  BorderRadius02,
  Layer02,
  Spacing05,
  Spacing04,
  TextBodyReg3FontFamily,
  TextBodyReg3FontWeight,
  TextBodyReg3FontSize,
  TextBodyReg3LetterSpacing,
  TextBodyReg3LineHeight,
  TextBodyReg3TextCase,
  TextBodyReg3TextDecoration,
  Layer01,
  IconMedium600
} from '@RHEnvironmental/pattern-library-tokens';
import {px} from './px';
import {inputAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';
import {FallbackFonts} from './fallbackFonts';

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(inputAnatomy.keys);

const primary = definePartsStyle({
  field: {
    backgroundColor: Layer02,
    borderWidth: px(BorderWidth01),
    borderColor: BorderLight500,
    borderRadius: px(BorderRadius02),
    color: TextLight500,
    marginLeft: '1px',
    marginRight: '1px',
    px: px(Spacing05),
    py: px(Spacing04),
    fontFamily: TextBodyReg3FontFamily+FallbackFonts,
    fontWeight: TextBodyReg3FontWeight,
    lineHeight: px(TextBodyReg3LineHeight),
    fontSize: px(TextBodyReg3FontSize),
    letterSpacing: TextBodyReg3LetterSpacing,
    textTransform: TextBodyReg3TextCase,
    textDecoration: TextBodyReg3TextDecoration,
    height: 'auto',
    _focus: {
      backgroundColor: Layer01,
      margin: 0,
      color: TextBlack800,
      borderColor: BorderFocus,
      borderWidth: px(BorderWidth02),
    },
    _invalid: {
      backgroundColor: Layer01,
      color: TextBlack800,
      borderColor: BorderError,
      borderWidth: px(BorderWidth02),
    },
    /** This affects only date fields, specifically in Webkit browsers **/
    '::-webkit-calendar-picker-indicator': {
      width: '100%',
      height: '100%',
      left: 0,
      position: 'absolute',
      opacity: 0,
    },
  },
  element: {
    px: px(Spacing05),
    py: px(Spacing04),
    color: IconMedium600,
  }
});

export const Input = defineMultiStyleConfig({
  variants: {
    primary,
  },
  defaultProps: {
    variant: 'primary',
  },
});