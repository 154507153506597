import {Box, Heading, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr, VStack} from '@chakra-ui/react';
import {PermissionsGuard} from '../admin/permissions/PermissionsGuard';
import {PERMISSION_ORGANISATION_LIST} from '../admin/permissions/Permissions';
import {OrganisationRow} from '../organisation/OrganisationRow';
import {Trans} from 'react-i18next';
import {useGetOrganisations} from '../api/client';
import usePages from '../hooks/usePages';
import {Pagination} from '../components/Pagination';
import {ApplicationErrorText} from '../error/ApplicationErrorText';
import {ModalAlert} from '../components/ModalAlert';

export const ListOrganisationsPage = () => {
  const {page, decrementPage, incrementPage} = usePages();
  const {data, isLoading, isError, error} = useGetOrganisations({
    page,
  });

  const organisations = data?.data;

  if (isError && error) {
    return <ModalAlert><ApplicationErrorText error={error} /></ModalAlert>;
  }

  if (isLoading) {
    return <Spinner data-testid={'list-organisations-page__loading'} />;
  }

  if (!organisations || organisations.length === 0 || !data) {
    return <Trans>No results found.</Trans>;
  }

  const rows = organisations.map(
    organisation => <OrganisationRow key={organisation.id} organisation={organisation} />
  );

  return <PermissionsGuard permission={PERMISSION_ORGANISATION_LIST}>
    <Box ml={10} mt={10}>
      <Heading as="h1">
        <Trans>Authorities</Trans>
      </Heading>
    </Box>
    <TableContainer mx={10} mt={2} mb={4} boxShadow={'md'} borderRadius={'md'}>
      <Table variant={'rounded'}>
        <Thead>
          <Tr>
            <Th>
              <Trans>Name</Trans>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows}
        </Tbody>
      </Table>
      <VStack align={'flex-end'} m={2}>
        <Pagination
          pagination={data.meta}
          onNext={incrementPage}
          onPrev={decrementPage}
        />
      </VStack>
    </TableContainer>
  </PermissionsGuard>;
};