import {defineStyleConfig} from '@chakra-ui/react';
import {
  TextLink,
} from '@RHEnvironmental/pattern-library-tokens';

export const Link  = defineStyleConfig({
  variants: {
    primary: {
      color: TextLink,
      textDecoration: 'underline',
      textStyle: 'textBodyLink4',
    }
  },
  defaultProps: {
    variant: 'primary',
  },
})