import {head} from 'ramda';
import {ValidationError as ApiValidationError} from '../../types/response/ValidationError';
import {ApplicationError} from '../../error/ApplicationError';
import {ErrorCode} from '../../error/ErrorCode';

export class ValidationError extends ApplicationError {
  public readonly validationErrors: ApiValidationError[];

  constructor(validationErrors: ApiValidationError[]) {
    super('Validation Error', ErrorCode.VALIDATION_ERROR);
    this.validationErrors = validationErrors;
  }

  public getForField(field: string) {
    return this.validationErrors.find((error) => {
      return head(error.path) == field;
    });
  }

  public existsForField(field: string) {
    return this.validationErrors.filter((error) => head(error.path) == field).length > 0;
  }
}