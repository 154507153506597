import {Box} from '@chakra-ui/react';

export function ArchiveIcon({height = '18px', width = '18px'}) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      height={height}
      width={width}
    >
      <path d="M17 18H1V7H3V16H15V7H17V18ZM0 0H18V6H0V0ZM6.5 8H11.5C11.78 8 12 8.22 12 8.5V10H6V8.5C6 8.22 6.22 8 6.5 8ZM2 2V4H16V2H2Z" fill="#6E6764" />
    </Box>
  );
}