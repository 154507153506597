import {BorderError, BorderLight500, BorderRadius02, BorderWidth01, BorderWidth02, Layer02, TextLight500} from '@RHEnvironmental/pattern-library-tokens';
import {defineStyle, defineStyleConfig} from '@chakra-ui/react';
import {px} from './px';

const primary = defineStyle({
  backgroundColor: Layer02,
  color: TextLight500,
  borderColor: BorderLight500,
  borderRadius: px(BorderRadius02),
  borderWidth: px(BorderWidth01),
  textStyle: 'text03',
  _invalid: {
    borderColor: BorderError,
    borderWidth: px(BorderWidth02),
  }
})

export const Textarea = defineStyleConfig({
  variants: {
    primary
  },
  defaultProps: {
    variant: 'primary'
  }
})