import {
  Button,
  Divider,
  Flex,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {ReactNode, useState} from 'react';
import {Trans} from 'react-i18next';
import {AddCircleIcon} from '../../icons/addCircleIcon';
import {InputsAddress} from './InputsAddress';
import {ModalConfirm} from '../ModalConfirm';

export const InputsAddresses = () => {
  const [addresses, setAddresses] = useState<ReactNode[]>([]);
  const [index, setIndex] = useState<number>(1);
  const {isOpen, onOpen, onClose} = useDisclosure();

  const addAddress = () => {
    onClose();
    setAddresses(prevAddresses => (
      [...prevAddresses,
        <>
          <Divider/>
          <InputsAddress key={index} index={index}/>
        </>
      ]
    ));
    setIndex(prevIndex => prevIndex+1);
  }

  return (
    <>
      <ModalConfirm isOpen={isOpen} onClose={onClose} title="Add another business" callback={addAddress}>
        <Text><Trans>You are about to add a new entry for another business. Please note that this cannot be removed later, and the only way to complete this form will be to start again.</Trans></Text>
        <Text mt={3}><Trans>Are you sure you want to add another business?</Trans></Text>
      </ModalConfirm>
      {...addresses}
      <Flex w='100%'>
        <Button
          leftIcon={<AddCircleIcon />}
          variant='tertiary'
          onClick={onOpen}
          padding={0}
        >
          <Trans>Add another business</Trans>
        </Button>
        <Spacer />
      </Flex>
    </>
  )
}