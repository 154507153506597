import {BrowserTracing} from '@sentry/tracing';
import {BrowserRouter} from 'react-router-dom';
import {ChakraProvider} from '@chakra-ui/react';
import './lang/i18n';
import {Routes} from './routes';
import {theme} from './theme';
import * as Sentry from '@sentry/react';
import {QueryClientProvider} from '@tanstack/react-query';
import {queryClient} from './api/queryClient';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: (process.env.SENTRY_SAMPLE_RATE) ? parseFloat(process.env.SENTRY_SAMPLE_RATE) : 0.0,
  });
}

export function App() {
  return <ChakraProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </QueryClientProvider>
  </ChakraProvider>
}