export interface BaseLicence {
  id: string;
  licence_type_id: string;
  licence_number: string;
  issue_date: string;
  archived_date?: string;
  created_date: string;
  internal_notes: string;
  local_authority: string;
  licence_type: string;
  procedure_types: string;
  organisation_id: string;
}

export interface AnyLicence extends BaseLicence {
  practitioner_name?: string;
  address?: Address;
  business_name?: string;
  addresses?: Address[];
  certificate_holder_name?: string;
  number_of_workstations?: number;
  vehicle_registration_details?: string;
  vehicle_description?: string;
}

export interface PractitionerLicence extends BaseLicence {
  practitioner_name: string;
  addresses: Address[];
}

export interface CertificatePremisesLicence extends BaseLicence {
  business_name: string;
  addresses: [Address];
  certificate_holder_name: string;
  number_of_workstations: number;
}

export interface CertificateVehicleLicence extends BaseLicence {
  business_name: string;
  certificate_holder_name: string;
  vehicle_registration_details: string;
  vehicle_description: string;
}

export interface CreateLicenceType {
  issue_date: string;
  licence_number: string;
  licence_type_id: string;
  procedure_type_ids: string[];
  internal_notes?: string;
  local_authority_id?: string;
  practitioner_name: string | null;
  addresses?: Address[];
  business_name: string | null;
  certificate_holder_name: string | null;
  number_of_workstations: number | null;
  vehicle_registration_details: string | null;
  vehicle_description: string | null;
}

export enum LicenceTypes {
  CERTIFICATE_PREMISES = 'certificatePremises',
  CERTIFICATE_VEHICLE = 'certificateVehicle',
  PRACTITIONER = 'practitioner',
}

export interface EditLicence {
  id: string;
  issue_date: string;
  archived_date?: string;
  licence_number: string;
  licence_type_id: string;
  procedure_type_ids: string[];
  procedure_types: ProcedureType[];
  internal_notes?: string;
  local_authority_id?: string;
  practitioner_name: string | null;
  addresses?: Address[];
  business_name: string | null;
  certificate_holder_name: string | null;
  number_of_workstations: number | null;
  vehicle_registration_details: string | null;
  vehicle_description: string | null;
}

export enum Language {
  en = 'en',
  cy = 'cy',
}

export interface Entry {
  id?: string;
  name?: string;
  language?: Language;
}

export interface LocalAuthority extends Required<Entry> {
  local_authority_id: string;
}

export interface ProcedureType extends Required<Entry> {
  procedure_type_id: string;
}

export interface LicenceType extends Required<Entry> {
  licence_type_id: string;
}

export interface Search {
  searchTerm?: string;
  localAuthority?: string;
  procedureTypes?: string;
  licenceType?: string;
  showArchived?: string;
  urn?: string;
}

export interface Address {
  associated_business_name: string;
  address_line1: string;
  address_line2: string;
  town: string;
  postcode: string;
}
