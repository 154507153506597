import {BorderError, TextDark800} from '@RHEnvironmental/pattern-library-tokens';

export const figmaColors = {
  'warningLight': '#f3efe8',
  'warningDark': '#f5ba31',
  'base1-100': '#E5E9FF',
  'base1-200': '#C3C8E6',
  'base1-300': '#8C9BCD',
  'base1-400': '#5F77B9',
  'base1-500': '#2D5AB4',
  'base1-600': '#284687',
  'base1-700': '#193264',
  'base1-800': '#051441',
  'base2-100': '#e6e8f5',
  'base2-200': '#c3c8e6',
  'base2-300': '#8c9bcd',
  'base2-400': '#5f77b9',
  'base2-500': '#2d5ab4',
  'base2-600': '#284687',
  'base2-700': '#193264',
  'base2-800': '#051441',
  'neutral-100': '#e4e2e2',
  'neutral-200': '#cac6c5',
  'neutral-300': '#ada6a4',
  'neutral-400': '#938b88',
  'neutral-500': '#6e6764',
  'neutral-600': '#5e5856',
  'neutral-700': '#4f4a48',
  'neutral-800': '#403b39',
  'grey-100': '#fafafa',
  'grey-200': '#f5f5f5',
  'grey-300': '#ededed',
  'grey-400': '#e6e6e6',
  'grey-500': '#595959',
  'grey-600': '#4d4d4d',
  'grey-700': '#333333',
  'grey-800': '#1a1a1a',
  'border-radius-01': '2',
  'border-radius-02': '4',
  'success-light': '#e8f3ec',
  'success-dark': '#08a141',
  'information-light': '#dbe0eb',
  'information-dark': '#2471f5',
  'error-light': '#f3ebe8',
  'error-dark': '#c30909',
  'warning-light': '#f3efe8',
  'warning-dark': '#f5ba31',
  'shadow-100': '#1a1a1a1a',
  'shadow-200': '#1a1a1a33',
  'shadow-300': '#1a1a1a4d',
  'shadow-400': '#1a1a1a66',
  'shadow-500': '#1a1a1a80',
  'shadow-600': '#1a1a1a99',
  'shadow-700': '#1a1a1ab3',
  'shadow-800': '#1a1a1acc',
  'shadow-900': '#1a1a1ae6'
}


export const colors = {
  primary: {
    text: figmaColors['base1-500'],
    button: {
      bg: figmaColors['base1-500'],
      text: figmaColors['grey-100'],
      hover: {
        bg: figmaColors['base1-600']
      },
      active: {
        bg: figmaColors['base1-700']
      },
      disabled: {
        bg: figmaColors['neutral-300'],
      }
    }
  },
  error: {
    text: TextDark800,
    bg: figmaColors['error-light'],
    icon: BorderError,
  },
};