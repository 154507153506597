import {SpecificInputType} from '../../types/validation/Input';
import {SPSelect} from './SPSelect';
import {useGetLocalAuthorities} from '../../api/client';
import {useLanguage} from '../../hooks/useLanguage';
import {Spinner} from '@chakra-ui/react';
import {LocalAuthorityName} from '../../api/client.schemas';

export const SelectLocalAuthority = ({id = 'localAuthority',required = false, enabled=true}:SpecificInputType) => {
  const language = useLanguage();
  const {data: localAuthorities, isLoading} = useGetLocalAuthorities({
    language,
  }, {
    query: {
      enabled,
    }
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (!localAuthorities) {
    return null;
  }

  return (
    <SPSelect id={id} label="Local authority" required={required} placeholder="Select local authority">
      {localAuthorities.results.map(({name, local_authority_id}: LocalAuthorityName, index: number) => (
        <option key={index} value={local_authority_id}>{name}</option>
      ))}
    </SPSelect>
  )
}