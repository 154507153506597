import {InputsAddress} from '../../components/Inputs/InputsAddress';
import {InputsAddresses} from '../../components/Inputs/InputsAddresses';
import {SPInput} from '../../components/Inputs/SPInput';
import {SPTextarea} from '../../components/Inputs/SPTextarea';
import {LicenceSubmissionWrapper} from './LicenceSubmissionWrapper';

export const PractitionerDetails = () => {
  return (
    <LicenceSubmissionWrapper header="Create practitioner licence details">
      <SPInput
        id='practitioner_name'
        required={true}
        label='Practitioner name'
        placeholder='Enter practitioner name'
        errors={{
          required: 'Please enter the practitioner name'
        }}
      />
      <InputsAddress />
      <InputsAddresses />
      <SPTextarea
        id='internal_notes'
        label='Internal notes (optional)'
      />
    </LicenceSubmissionWrapper>
  )
}