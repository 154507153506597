import {defineStyleConfig} from '@chakra-ui/react';
import {

  Spacing01,
  TextBodyMedium4FontSize,
  TextDark800,
} from '@RHEnvironmental/pattern-library-tokens';
import {px} from './px';


export const FormLabel  = defineStyleConfig({
  variants: {
    primary: {
      color: TextDark800,
      textStyle: 'textBodyMedium4',
      marginBottom: px(Spacing01),
      fontSize: px(TextBodyMedium4FontSize),
    }
  },
  defaultProps: {
    variant: 'primary',
  },
})