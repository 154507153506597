import React from 'react';
import {ValidationError} from '../api/validation/ValidationError';
import {ApplicationError} from '../error/ApplicationError';

interface NestedComponentErrorContextValue {
  error: ApplicationError | undefined | ValidationError,
  setError: (error: ApplicationError | undefined | ValidationError) => void,
}

export const NestedComponentErrorContext = React.createContext<NestedComponentErrorContextValue>({
  error: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setError: () => {},
});