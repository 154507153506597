import {VStack, Center, Heading, Spinner} from '@chakra-ui/react';
import {Trans} from 'react-i18next';
import {Spacing02, Spacing08, TextLight500} from '@RHEnvironmental/pattern-library-tokens';
import {px} from '../theme/px';
import {PermissionsGuard} from '../admin/permissions/PermissionsGuard';
import {PERMISSION_LICENCE_CREATE} from '../admin/permissions/Permissions';
import {useGetLicence} from '../api/client';
import {EditLicenceComponent} from '../edit_licence/EditLicenceComponent';
import {useSearchParams} from 'react-router-dom';
import {OrganisationGuard} from '../organisation/OrganisationGuard';

export const EditLicencePage = () => {
  const [params] = useSearchParams();
  const {data: licenceDetails, isLoading} = useGetLicence({
    id : params.get('urn') ?? undefined,
  }, {
    query: {
      enabled: params.has('urn'),
    }
  });

  if (isLoading) {
    return <Spinner/>;
  }

  if (!licenceDetails) {
    return <VStack alignContent='center'>
      <Heading as='h2' variant='Lt4' mt={Spacing02} color={TextLight500}>
        <Trans>No licence details found</Trans>
      </Heading>
    </VStack>;
  }

  return (
    <OrganisationGuard organisationId={licenceDetails.results.organisation_id}>
      <PermissionsGuard permission={PERMISSION_LICENCE_CREATE}>
        <Center mt={px(Spacing08)}>
          <EditLicenceComponent licence={licenceDetails.results} />
        </Center>
      </PermissionsGuard>
    </OrganisationGuard>
  )
}
