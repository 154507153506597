import {isEmpty, path} from 'ramda';
import {FieldError, FieldErrors} from 'react-hook-form';

export const extractError = (errors: FieldErrors, id: string): string | undefined => {
  if (!errors || isEmpty(errors)){
    return undefined;
  }

  const parts = id.split('.');
  const errorObject = path<FieldError>(parts, errors);

  if (!errorObject) {
    return undefined;
  }

  return errorObject.message;
};