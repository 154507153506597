import {Textarea} from '@chakra-ui/react';
import {GenericInputType} from '../../types/validation/Input';
import {InputWrapper} from './InputWrapper';
import {ErrorCode} from '../../error/ErrorCode';
import {errorMessages} from '../../error/errorMessages';
import {useFormContext} from 'react-hook-form';

export const SPTextarea = ({id, required, label, placeholder = '', errors}:GenericInputType) => {

  const {register} = useFormContext();

  const DEFAULT_ERROR = {
    required: (required ? errorMessages[ErrorCode.EMPTY_INPUT_VALIDATION] : false),
  }

  const ERRORS = !errors ? DEFAULT_ERROR : errors;

  return (
    <InputWrapper id={id} required={required} label={label}>
      <Textarea 
        {...register(id, ERRORS)}
        placeholder={placeholder}
        resize='vertical'
      />
    </InputWrapper>
  )
}