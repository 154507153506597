import {Select} from '@chakra-ui/react';
import {Trans} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import {ErrorCode} from '../../error/ErrorCode';
import {errorMessages} from '../../error/errorMessages';
import {GenericSelectType} from '../../types/validation/Input';
import {InputWrapper} from './InputWrapper';
import {useMemo, useState} from 'react';

export const SPSelect = ({id, required = false, placeholder = '', label = '', onChanged, errors, children, disabled}:GenericSelectType) => {
  const {register, getValues, setValue} = useFormContext();
  const [fieldValue, setFieldValue] = useState<string>(getValues(id) || '');

  useMemo(() => {
    if(onChanged){
      onChanged(fieldValue);
      setValue(id, fieldValue, {shouldValidate: true});
    }
  }, [fieldValue]);

  const DEFAULT_ERROR = {
    required: (required ? errorMessages[ErrorCode.EMPTY_SELECT_VALIDATION] : false),
  }

  const ERRORS = !errors ? DEFAULT_ERROR : errors;
  
  return (
    <InputWrapper id={id} label={label} required={required}>
      <Select
        {...register(id, ERRORS)}
        onChange={(e) => setFieldValue(e.target.value)}
        value={fieldValue}
        disabled={disabled}
      >
        <option value=""><Trans>{placeholder}</Trans></option>
        {children}
      </Select>
    </InputWrapper>
  )
}