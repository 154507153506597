import {Box4RightLightBlur, Box4RightLightColor, Box4RightLightSpread, Box4RightLightX, Box4RightLightY, Layer01} from '@RHEnvironmental/pattern-library-tokens';
import {makeBoxShadow} from './makeBoxShadow';
import {HEADER_HEIGHT} from './Header';

export const searchPanelStyle = {
  top: HEADER_HEIGHT,
  height: `100vh - ${HEADER_HEIGHT}`,
  width: '320px',
  zIndex: 1,
}

export const searchComponentStyle = {
  height: '100%',
  width: '320px',
  backgroundColor: Layer01,
  boxShadow:  makeBoxShadow(Box4RightLightX, Box4RightLightY, Box4RightLightBlur, Box4RightLightSpread, Box4RightLightColor),
  overflowX: 'hidden',
  overflowY: 'scroll',
}