import {useEffect, useRef, useState} from 'react';
import {useQuery} from './useQuery';
import {AnyLicence} from '../types';
import {SearchActions} from '../types/search';

interface SearchState {
  type: SearchActions;
  searchParams: URLSearchParams;
}

const objFromUrlSearchParams = (params:URLSearchParams) => {
  const paramsObj:{[key:string]: string} = {};
  for(const [key, value] of params){
    paramsObj[key] = value;
  }
  return paramsObj;
}

export const useSearch = () => {
  const DEFAULT_SKIP = 0;
  const DEFAULT_TAKE = 20;
  const [searchState, dispatchSearchQuery] = useState<SearchState>({type:SearchActions.READ, searchParams: new URLSearchParams});
  const {results, setSearchParams, error, isLoading} = useQuery();
  const nextQueryStart = useRef<number>(DEFAULT_TAKE);
  const [searchResults, setSearchResults] = useState<AnyLicence[]>([]);

  useEffect(() => {
    if(searchState?.searchParams && searchState.type){
      const params = searchState.searchParams;
      if(!searchState.searchParams.has('skip')){
        searchState.searchParams.set('skip', DEFAULT_SKIP.toString());
      }
      if(!searchState.searchParams.has('take')) {
        searchState.searchParams.set('take', DEFAULT_TAKE.toString());
      }
      if(searchState.type === SearchActions.NEXT){
        searchState.searchParams.set('skip', nextQueryStart.current.toString());
      }else{
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        nextQueryStart.current = parseInt(searchState.searchParams.get('take')!) || DEFAULT_TAKE;
      }
      setSearchParams(objFromUrlSearchParams(params));
    }
  }, [searchState]); 

  useEffect(() => {
    if(searchState?.searchParams && searchState.type){
      if(searchState.type === SearchActions.NEXT){
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const skip = parseInt(searchState.searchParams.get('skip')!) || DEFAULT_SKIP;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const take = parseInt(searchState.searchParams.get('take')!) || DEFAULT_TAKE;
        nextQueryStart.current = (nextQueryStart.current || skip) + take;
        setSearchResults((prevSearchResults) => [...prevSearchResults,...results]);
      }else{
        setSearchResults(results);
      }
    }
  }, [results]);

  return {searchResults, dispatchSearchQuery, error, isLoading, nextQueryStart, DEFAULT_TAKE};
}
