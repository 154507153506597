import {BorderWidth01, BorderLight500, Spacing05, Spacing03} from '@RHEnvironmental/pattern-library-tokens';
import {px} from '../../px';

export const lightBody = {
  padding: px(Spacing05),
  borderBottomWidth: px(BorderWidth01),
  borderBottomColor: BorderLight500,
  gap: px(Spacing03),
  align: 'flex-start',
}

export const darkerBody = {
  borderBottomWidth: px(BorderWidth01),
  borderBottomColor: BorderLight500,
  padding: px(Spacing05),
  gap: px(Spacing03),
  alignItems: 'flex-start'
}