import {ApplicationError} from './ApplicationError';
import {useApplicationErrorMessage} from './useApplicationErrorMessage';

interface ApplicationErrorAlertProps {
    error: ApplicationError
}

export const ApplicationErrorText = (props: ApplicationErrorAlertProps) => {
  const {get} = useApplicationErrorMessage();
  return <span>{get(props.error)}</span>;
}