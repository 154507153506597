import React from 'react';
import {Box, HStack, List, ListItem, Link, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

import RheLogoVector from '../images/RheLogoVector';

import * as tokens from '@RHEnvironmental/pattern-library-tokens';

const Footer = () => {
  const {t} = useTranslation();

  return (
    <Box
      color={tokens.TextWhite100}
      p={8}
      bg={tokens.CanvasDarkGrey}
      w={'100%'}
    >
      <HStack
        spacing={[tokens.Spacing04, tokens.Spacing06, tokens.Spacing08]}
        align="start"
        flexDirection={['column', 'row', 'row']}
      >
        <HStack align="start">
          <Box w={60}>
            <Link href={process.env.RHE_GLOBAL_URL} target="_blank">
              <RheLogoVector />
            </Link>
          </Box>
          <Box>
            <Text color="#CAC9C8">
              {t('smarter')}<br />
              {t('public')}<br />
              {t('protection')}
            </Text>
          </Box>
        </HStack>
        <Box>
          <Text fontWeight="bold">
            {t('Help')}
          </Text>
          <List>
            <ListItem>
              <Link href={process.env.ACCESSIBILITY_URL} color={tokens.TextWhite100} textDecoration="none" isExternal>
                {t('Accessibility')}
              </Link>
            </ListItem>
            <ListItem>
              <Link href={process.env.CONTACT_URL} color={tokens.TextWhite100} textDecoration="none" isExternal>
                {t('Contact')}
              </Link>
            </ListItem>
          </List>
        </Box>
        <Box>
          <Text fontWeight="bold">
            {t('Legal')}
          </Text>
          <List>
            <ListItem>
              <Link href={process.env.COPYRIGHT_URL} color={tokens.TextWhite100} textDecoration="none" isExternal>
                {t('Copyright')}
              </Link>
            </ListItem>
            <ListItem>
              <Link href={process.env.TERMS_AND_CONDITIONS_URL} color={tokens.TextWhite100} textDecoration="none" isExternal>{t('Terms Conditions')}</Link>
            </ListItem>
          </List>
        </Box>
      </HStack>
      <Text mt={10}>&copy; {t('RH Environmental Limited trading as RHE Global. All rights reserved.')}</Text>
    </Box>
  )
}

export default Footer;
