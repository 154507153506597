import {Box, Button, Heading, VStack, Text, Center} from '@chakra-ui/react';
import {Spacing05, Spacing02, Spacing08, TextLight500} from '@RHEnvironmental/pattern-library-tokens';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {px} from '../theme/px';
import QuestionMarkImage from '../images/question.png';

export const NotFoundPage = () => {
  return <Center
    height='100%'
    backgroundImage={`url(${QuestionMarkImage})`}
    backgroundRepeat='no-repeat'
    backgroundPosition='center'
  >
    <VStack role='main'>
      <Box pb={px(Spacing05)}>
        <Heading textStyle='headingLtPrimary' as='h1' tabIndex={0}>
          <Trans>Oops, page not found!</Trans>
        </Heading>
      </Box>
      <Box display='inline-block'>
        <Box pb={px(Spacing02)} textAlign='center'>
          <Text textStyle='headingLt4' color={TextLight500}>
            <Trans>404 error.</Trans>
          </Text>
        </Box>
        <Box pb={px(Spacing08)} textAlign='center'>
          <Text textStyle='text03' color={TextLight500}>
            <Trans>
              We are sorry for the inconvenience.<br />It looks like you're trying to access a page that has been<br />
              moved or deleted.
            </Trans>
          </Text>
        </Box>
      </Box>
      <Box>
        <Link to="/">
          <Button variant='primary'>
            <Trans>Back to homepage</Trans>
          </Button>
        </Link>
      </Box>
    </VStack>
  </Center>
}