import {
  Layer02,
  TextBodyReg3FontFamily,
  TextBodyReg3FontWeight,
  TextBodyReg3FontSize,
  TextBodyReg3LineHeight,
  TextBodyReg3LetterSpacing,
  TextBodyReg3TextCase,
  TextBodyReg3TextDecoration,
  TextLight500,
  Spacing04,
  Spacing05,
  BorderFocus,
  BorderLight500,
  BorderRadius02,
  BorderWidth01,
  BorderWidth02,
  Layer01,
  TextBlack800,
  BorderError
} from '@RHEnvironmental/pattern-library-tokens';
import {px} from './px';
import {inputAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';
import {FallbackFonts} from './fallbackFonts';

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(inputAnatomy.keys);

const primary = definePartsStyle({
  field: {
    backgroundColor: Layer02,
    borderWidth: px(BorderWidth01),
    borderColor: BorderLight500,
    borderRadius: px(BorderRadius02),
    color: TextLight500,
    fontFamily: TextBodyReg3FontFamily+FallbackFonts,
    fontWeight: TextBodyReg3FontWeight,
    lineHeight: px(TextBodyReg3LineHeight),
    fontSize: px(TextBodyReg3FontSize),
    letterSpacing: TextBodyReg3LetterSpacing,
    textTransform: TextBodyReg3TextCase,
    textDecoration: TextBodyReg3TextDecoration,
    px: px(Spacing05),
    py: px(Spacing04),
    height: 'auto',
    _focus: {
      backgroundColor: Layer01,
      color: TextBlack800,
      borderColor: BorderFocus,
      borderWidth: px(BorderWidth02),

    },
    _invalid: {
      backgroundColor: Layer01,
      color: TextBlack800,
      borderColor: BorderError,
      borderWidth: px(BorderWidth02),
    }
  },
});

export const Select = defineMultiStyleConfig({
  variants: {
    primary,
  },
  defaultProps: {
    variant: 'primary',
  },
});