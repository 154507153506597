import {useContext} from 'react';
import {Outlet} from 'react-router-dom';
import {AuthenticationContext} from '../login/AuthenticationContext';
import {LoginPage} from '../pages/LoginPage';

export const AuthenticationGuard = () => {
  const {isLoggedIn, token} = useContext(AuthenticationContext);

  if (!isLoggedIn || !token) {
    return <LoginPage />;
  }

  return <Outlet />
}