import React, {useContext, useEffect} from 'react';
import {useWindowSize} from 'react-use';
import {Center, Heading, Text, VStack, Button, Link} from '@chakra-ui/react';
import {px} from '../theme/px';
import {Spacing04, Spacing05, Spacing06, Spacing08} from '@RHEnvironmental/pattern-library-tokens';
import {SearchContext} from '../search/SearchContext';
import {Trans, useTranslation} from 'react-i18next';
import {breakpoints} from '../theme/breakpoints';

const MarketingPage = () => {
  const textStyle = 'textBodyRegular2'
  const {setIsSearchPanelOpen} = useContext(SearchContext);
  const {width} = useWindowSize();
  const {t} = useTranslation();

  useEffect(() => {
    if (width <= parseInt(breakpoints.sm)) {
      setIsSearchPanelOpen(true);
    }
  }, []);

  return (
    <Center>
      <VStack width='70%'>
        <Heading
          as='h1'
          tabIndex={-1}
          variant='Md2Primary'
          mt={px(Spacing08)}
          mb={px(Spacing06)}
          textAlign='center'
        >
          {t('Special Procedures Wales Public Register')}
        </Heading>
        <Text
          sx={{textStyle}}
          textAlign='center'
        >
          {t('site-intro')}
        </Text>
        <Text
          sx={{textStyle}}
          textAlign='center'
        >
          {t('Further details can be found')} <Link
            sx={{textStyle}}
            textAlign="center"
            target="_blank"
            href="https://riams.org/connect/documents/iqlbcUWFB07X"
          >{t('here')}</Link>.
        </Text>
        <Button
          onClick={() => setIsSearchPanelOpen(true)}
          w="288px"
          my={px(Spacing06)}
          px={px(Spacing05)}
          py={px(Spacing04)}>
          {t('Let\'s get started')}
        </Button>
      </VStack>
    </Center>
  )
}

export default MarketingPage;
