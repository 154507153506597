import {useContext} from 'react';
import {Text} from '@chakra-ui/react';
import {Trans} from 'react-i18next';
import {getLicenceType} from '../components/getLicenceType';
import {LicenceTypes} from '../types';
import {CreateLicenceContext} from './context/CreateLicenceContext';
import {CreateLicenceComponent} from './CreateLicenceComponent';
import {CertificatePremisesDetails} from './enter_details/CertificatePremisesDetails';
import {CertificateVehicleDetails} from './enter_details/CertificateVehicleDetails';
import {PractitionerDetails} from './enter_details/PractitionerDetails';
import {useFormContext} from 'react-hook-form';
import {CreateLicenceScreens} from '../types/CreateLicence';

export const CreateLicenceStep = () => {
  const {step} = useContext(CreateLicenceContext);
  const {getValues} = useFormContext();

  const licence_type_id = getValues('licence_type_id');

  if(step === CreateLicenceScreens.BASIC_DETAILS){
    return <CreateLicenceComponent />;
  }else if (step === CreateLicenceScreens.ADDITIONAL_DETAILS){
    switch (getLicenceType(licence_type_id)) {
    case LicenceTypes.CERTIFICATE_PREMISES:
      return <CertificatePremisesDetails />;

    case LicenceTypes.CERTIFICATE_VEHICLE:
      return <CertificateVehicleDetails />;

    case LicenceTypes.PRACTITIONER:
      return <PractitionerDetails />;

    default:
      return <Text><Trans>An error has occurred please try again</Trans></Text>;
    }
  }
  return <Text><Trans>An error has occurred please try again</Trans></Text>;
}