import {
  Spacing05,
  BorderWidth04,
  BorderError, BorderWidth02,
} from '@RHEnvironmental/pattern-library-tokens';
import {px} from './px';

export const layerStyles = {
  primaryAlertBox: {
    bg: 'error.bg',
    color: 'error.text',
    borderLeftWidth: BorderWidth04,
    borderLeftColor: BorderError,
    padding: px(Spacing05),
  },
  primaryModalAlert: {
    bg: 'error.bg',
    color: 'error.text',
    borderBottomWidth: BorderWidth02,
    borderBottomColor: BorderError,
    padding: px(Spacing05),
    textStyle: 'textBodyRegular4',
  },
};