import {AxiosError} from 'axios';
import {hasPath, path} from 'ramda';
import {ApplicationError} from '../../error/ApplicationError';
import {ErrorCode} from '../../error/ErrorCode';
import {ValidationError} from './ValidationError';
import {ValidationError as ApiValidationError} from '../../types/response/ValidationError';

export const makeValidationErrorOrUnknown = (error: AxiosError) => {
  const errorDetailPath = ['response', 'data', 'validationErrors'];

  if (!hasPath(errorDetailPath, error) || !Array.isArray(path(errorDetailPath, error))) {
    return new ApplicationError('Unknown Error', ErrorCode.UNKNOWN);
  }

  return new ValidationError(path<ApiValidationError[]>(errorDetailPath, error) as ApiValidationError[]);
}