import {BorderRadius04, BorderWidth01, Spacing02, Spacing04, Spacing05} from '@RHEnvironmental/pattern-library-tokens';
import {px} from '../../px';
import {figmaColors} from '../../colors';

export const adminSectionStyle = {
  paddingY: px(Spacing04),
  paddingX: px(Spacing05),
  gap: px(Spacing02),
  alignItems: 'flex-start',
  borderRadius: `${BorderRadius04}px ${BorderRadius04}px 0 0`
}

export const adminButton = {
  backgroundColor: figmaColors['grey-100'],
  borderColor: figmaColors['neutral-500'],
  borderRadius: px(BorderRadius04),
  borderWidth: px(BorderWidth01),
  color: figmaColors['neutral-500'],
  _hover: {
    color: figmaColors['neutral-600'],
    borderColor: figmaColors['neutral-600'],
  },
  _active: {
    color: figmaColors['neutral-700'],
    borderColor: figmaColors['neutral-700'],
  }
}
