import {useContext, useRef} from 'react';
import {Center} from '@chakra-ui/react';
import {CreateLicenceContext} from './context/CreateLicenceContext';
import {CreateLicenceScreens} from '../types/CreateLicence';
import {CreateLicenceStepLayout} from './CreateLicenceStepLayout';
import {InputIssueDate} from '../components/Inputs/InputIssueDate';
import {SelectLicenceType} from '../components/Inputs/SelectLicenceType';
import {SelectProcedureTypes} from '../components/Inputs/SelectProcedureTypes';
import {useFormContext} from 'react-hook-form';
import {OPTIONAL_VALUES} from './FormValues';
import {getLicenceType} from '../components/getLicenceType';
import {InputLicenceNumber} from '../components/Inputs/InputLicenceNumber';

export const CreateLicenceComponent = () => {
  const {setStep} = useContext(CreateLicenceContext);
  const {getValues, reset} = useFormContext();
  const licenceTypeIdChanged = useRef<boolean>(false);
  
  const checkLicenceChanged = (value:string) => {
    licenceTypeIdChanged.current = (getLicenceType(value) !== getLicenceType(getValues('licence_type_id')));
  }

  const onSubmit = () => {
    if(licenceTypeIdChanged.current){
      reset(formValues => ({
        ...formValues,
        ...OPTIONAL_VALUES
      }));
    }
    setStep(CreateLicenceScreens.ADDITIONAL_DETAILS);
  }

  return (
    <Center height='100%'>
      <CreateLicenceStepLayout header='Create a licence' onSubmit={onSubmit}>
        <SelectLicenceType id='licence_type_id' required onChanged={checkLicenceChanged}/>
        <InputLicenceNumber id='licence_number' required/>
        <SelectProcedureTypes id='procedure_type_ids' required/>
        <InputIssueDate width='50%' id='issue_date' required/>
      </CreateLicenceStepLayout>
    </Center>
  )
}