import {Trans} from 'react-i18next';
import {getLicenceType} from '../components/getLicenceType';
import {LicenceTypes} from '../types';
import {EditCertificatePremisesDetails} from './edit_details/EditCertificatePremisesDetails';
import {EditCertificateVehicleDetails} from './edit_details/EditCertificateVehicleDetails';
import {EditPractitionerDetails} from './edit_details/EditPractitionerDetails';
import {useFormContext} from 'react-hook-form';

export const EditLicenceDetails = () => {
  const {getValues} = useFormContext();

  const licence_type_id = getValues('licence_type_id');

  switch (getLicenceType(licence_type_id)) {
  case LicenceTypes.CERTIFICATE_PREMISES:
    return <EditCertificatePremisesDetails />;

  case LicenceTypes.CERTIFICATE_VEHICLE:
    return <EditCertificateVehicleDetails />;

  case LicenceTypes.PRACTITIONER:
    return <EditPractitionerDetails />;

  default:
    return <Trans>An error has occurred please try again</Trans>;
  }
}