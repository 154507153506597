import {LicenceTypes} from '../types';

export const getLicenceType = (licenceTypeId:string):string|undefined => {
  const certificatePremisesLicenceIds = ['certificatePremisesLicence3Year', 'certificatePremisesLicence7Day'];
  const certificateVehicleLicenceIds = ['certificateVehicleLicence3Year', 'certificateVehicleLicence7Day'];
  const practitionerLicenceIds = ['practitionerLicence3Year', 'practitionerLicence7Day', 'traineeLicence'];
  
  if(certificatePremisesLicenceIds.includes(licenceTypeId)){
    return LicenceTypes.CERTIFICATE_PREMISES;
  }

  if(certificateVehicleLicenceIds.includes(licenceTypeId)){
    return LicenceTypes.CERTIFICATE_VEHICLE
  }

  if (practitionerLicenceIds.includes(licenceTypeId)) {
    return LicenceTypes.PRACTITIONER;
  }

  return undefined;
}