import {AxiosError, isAxiosError} from 'axios';
import {isUnauthenticatedError} from '../../api/isUnauthenticatedError';
import {isValidationError} from '../../api/isValidationError';
import {makeValidationErrorOrUnknown} from '../../api/validation/makeValidationErrorOrUnknown';
import {ApplicationError} from '../ApplicationError';
import {ErrorCode} from '../ErrorCode';
import {makeGenericApplicationError} from '../makeGenericApplicationError';
import {authenticationEmitter} from '../../login/authenticationEmitter';

export const apiErrorMapper = (error: Error) => {
  if (!isAxiosError(error)) {
    return undefined;
  }

  const axiosError = error as AxiosError;

  if (axiosError.code == 'ERR_NETWORK') {
    return new ApplicationError('Network Error', ErrorCode.NETWORK_ERROR);
  }

  if (axiosError.code == 'ERR_BAD_REQUEST') {
    if (isValidationError(axiosError)) {
      return makeValidationErrorOrUnknown(axiosError);
    } else if (axiosError.response?.status == 401 || axiosError.response?.status == 403) {
      authenticationEmitter.emit('unauthenticated');
      return new ApplicationError('Unauthenticated', ErrorCode.LOGGED_OUT);
    } else if (isUnauthenticatedError(axiosError)) {
      return new ApplicationError('Unauthenticated', ErrorCode.UNAUTHENTICATED);
    } else if (axiosError.response?.status == 400) {
      return makeGenericApplicationError(axiosError);
    }
  }

  return new ApplicationError('Unknown Error', ErrorCode.UNKNOWN);
}