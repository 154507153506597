import {AnyLicence} from '../../types';
import {PractitionerLicenceCard} from './PractitionerLicenceCard';
import {CertificateVehicleLicenceCard} from './CertificateVehicleLicenceCard';
import {CertificatePremisesLicenceCard} from './CertificatePremisesLicenceCard';
import {Text} from '@chakra-ui/react';
import {Trans} from 'react-i18next';
import {isPractitionerLicence} from '../../types/guards/isPractitionerLicence';
import {isCertificateVehicleLicence} from '../../types/guards/isCertificateVehicleLicence';
import {isCertificatePremisesLicence} from '../../types/guards/isCertificatePremisesLicence';

interface LicenceCardHandlerProps {
  licence: AnyLicence;
}

export const LicenceCardHandler = ({licence}:LicenceCardHandlerProps) => {
  if (isPractitionerLicence(licence)) {
    return <PractitionerLicenceCard licence={licence}/>
  }
  if (isCertificateVehicleLicence(licence)) {
    return <CertificateVehicleLicenceCard licence={licence}/>
  }
  if (isCertificatePremisesLicence(licence)) {
    return <CertificatePremisesLicenceCard licence={licence}/>;
  }
  return <Text tabIndex={0}><Trans>Error could not load card</Trans></Text>;
}