import {figmaColors} from './colors';

export const searchBackdrop = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: figmaColors['shadow-600'],
  overflow: 'hidden'
}

export const searchBackdropLg = {
  ...searchBackdrop,
  backgroundColor: 'transparent'
}