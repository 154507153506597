import {cardAnatomy} from '@chakra-ui/anatomy'
import {createMultiStyleConfigHelpers} from '@chakra-ui/react'

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(cardAnatomy.keys);
import {
  BorderLight200,
  BorderLight500,
  BorderRadius04,
  BorderWidth01,
  Box2DownLightBlur,
  Box2DownLightColor,
  Box2DownLightSpread,
  Box2DownLightX,
  Box2DownLightY,
  Box4DownDarkBlur,
  Box4DownDarkColor,
  Box4DownDarkSpread,
  Box4DownDarkX,
  Box4DownDarkY,
  CanvasDarkGrey,
  Layer01,
  Spacing03,
  Spacing04,
  Spacing05,
  TextMedium600,
  TextWhite100,
} from '@RHEnvironmental/pattern-library-tokens';
import {px} from '../px';
import {figmaColors} from '../colors';

const primary = definePartsStyle({
  container: {
    backgroundColor: Layer01,
    borderWidth: px(BorderWidth01),
    borderRadius:px(BorderRadius04),
    borderColor: BorderLight200,
    boxShadow: `${px(Box4DownDarkX)} ${px(Box4DownDarkY)} ${px(Box4DownDarkBlur)}  ${px(Box4DownDarkSpread)} ${Box4DownDarkColor}`,
  },
  header: {
    borderColor: BorderLight200,
    borderBottomWidth: px(BorderWidth01),
    paddingY: px(Spacing04),
    paddingX: px(Spacing05),
    textStyle: 'headingLt4',
  },
  body: {
    paddingY: '40px',
    paddingX: Spacing03,
  }
})

const result = definePartsStyle({
  container: {
    borderWidth: px(BorderWidth01),
    borderRadius: px(BorderRadius04),
    borderColor: BorderLight500,
    boxShadow: `${px(Box2DownLightX)} ${px(Box2DownLightY)} ${px(Box2DownLightBlur)}  ${px(Box2DownLightSpread)} ${Box2DownLightColor}`,
  },
  header: {
    borderColor: BorderLight200,
    borderBottomWidth: px(BorderWidth01),
    padding: 0,
    textStyle: 'headingLt4',
    color: TextWhite100,
    backgroundColor: figmaColors['base1-500'],
    borderRadius: `${BorderRadius04}px ${BorderRadius04}px 0 0`
  },
  body: {
    backgroundColor: figmaColors['grey-100'],
    textStyle: 'textBodyMedium3',
    color: TextMedium600,
    padding: 0
  },
  footer: {
    backgroundColor: figmaColors['grey-100'],
    borderRadius: `0 0 ${BorderRadius04}px ${BorderRadius04}px`,
    color: figmaColors['base1-500'],
    textStyle: 'textBodyReg3',
  }
})

const resultArchived = definePartsStyle({
  container: {
    borderWidth: px(BorderWidth01),
    borderRadius: px(BorderRadius04),
    borderColor: BorderLight500,
    boxShadow: `${px(Box2DownLightX)} ${px(Box2DownLightY)} ${px(Box2DownLightBlur)}  ${px(Box2DownLightSpread)} ${Box2DownLightColor}`,
  },
  header: {
    borderColor: BorderLight200,
    borderBottomWidth: px(BorderWidth01),
    padding: 0,
    textStyle: 'headingLt4',
    color: TextWhite100,
    backgroundColor: CanvasDarkGrey,
    borderRadius: `${BorderRadius04}px ${BorderRadius04}px 0 0`
  },
  body: {
    backgroundColor: figmaColors['grey-100'],
    textStyle: 'textBodyMedium3',
    color: TextMedium600,
    padding: 0
  },
  footer: {
    backgroundColor: figmaColors['grey-100'],
    borderRadius: `0 0 ${BorderRadius04}px ${BorderRadius04}px`,
    color: figmaColors['base1-500'],
    textStyle: 'textBodyReg3',
  }
})

export const Card = defineMultiStyleConfig({
  variants: {
    primary,
    result,
    resultArchived
  },
  defaultProps: {
    variant: 'primary',
  },
});
