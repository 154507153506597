import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react';
import {ReactNode, useContext, useState} from 'react';
import {CanvasInformationLight, Layer04, Spacing04, Spacing05, TextWhite100} from '@RHEnvironmental/pattern-library-tokens';
import {Trans, useTranslation} from 'react-i18next';
import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';
import {AuthenticationContext} from '../../login/AuthenticationContext';
import {adminButton, adminSectionStyle} from '../../theme/Card/ResultCard/CardHeader';
import {px} from '../../theme/px';
import {darkerBody, lightBody} from '../../theme/Card/ResultCard/CardBody';
import {RestoreIcon} from '../../icons/restoreIcon';
import {ArchiveIcon} from '../../icons/archiveIcon';
import {EditIcon} from '../../icons/editIcon';
import {figmaColors} from '../../theme/colors';
import {cardFooter} from '../../theme/Card/ResultCard/CardFooter';
import {CardInfo} from './CardInfo';
import {CertificatePremisesLicence, CertificateVehicleLicence, PractitionerLicence} from '../../types';
import {useNavigate} from 'react-router-dom';
import {useOrganisation} from '../../organisation/useOrganisation';

interface LicenceCardProps {
  licence: PractitionerLicence | CertificatePremisesLicence | CertificateVehicleLicence;
  header: string;
  children: ReactNode | ReactNode[];
  moreInfoShown?: boolean;
}

export const LicenceCard = ({
  licence,
  header,
  children,
  moreInfoShown=false,
}:LicenceCardProps) => {
  const [infoShown, setInfoShown] = useState(moreInfoShown);
  const {isLoggedIn} = useContext(AuthenticationContext);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const organisation = useOrganisation();

  const {
    id,
    issue_date,
    archived_date,
    internal_notes,
    procedure_types,
    local_authority,
    licence_type,
  } = licence;

  const isArchived = !!archived_date;
  const belongsToUsersOrganisation = (isLoggedIn && organisation?.id) ? licence.organisation_id === organisation.id : false;

  const status = (isArchived) ? t('dateSuffixedArchived', {
    date: archived_date,
  }) : t('dateSuffixedApproved', {
    date: issue_date,
  });

  return (
    <Card variant={isArchived ? 'resultArchived' : 'result'} >
      <CardHeader>
        {(isLoggedIn && belongsToUsersOrganisation) &&
          <VStack 
            sx={adminSectionStyle}
            backgroundColor={isArchived ? figmaColors['grey-800'] : figmaColors['base1-700']}
          >
            <Text sx={{textStyle: 'textBodyRegular3'}}>
              <strong><Trans>URN: </Trans></strong>{id}
            </Text>
            <HStack>
              {isArchived ?
                <Button variant='tertiary' sx={adminButton} leftIcon={<RestoreIcon />}>
                  <Trans>Restore</Trans>
                </Button>
                :
                <Button variant='tertiary' sx={adminButton} leftIcon={<ArchiveIcon/>}>
                  <Trans>Archive</Trans>
                </Button>
              }
              <Button onClick={() => navigate(`/edit-licence/?urn=${id}`)} variant='tertiary' sx={adminButton} leftIcon={<EditIcon/>}>
                <Trans>Edit</Trans>
              </Button>
            </HStack>
            <CardInfo
              title='Note'
              value={internal_notes || 'No notes added'}
              fontColour={TextWhite100}
            />
          </VStack>
        }
        <Box py={px(Spacing04)} px={px(Spacing05)}>
          {header}
        </Box>
      </CardHeader>
      <CardBody>
        <VStack 
          sx={darkerBody}
          backgroundColor={isArchived ? Layer04 : CanvasInformationLight}
        >
          {children}
        </VStack>
        {infoShown &&
          <SimpleGrid sx={lightBody} templateColumns={{base:'auto', lg:'auto auto'}}>
            <CardInfo title="Procedure type" value={procedure_types}/>
            <CardInfo title="Local authority" value={local_authority} />
            <CardInfo title="Licence type" value={licence_type} />
            <CardInfo title="Status" value={status} />
          </SimpleGrid>
        }
      </CardBody>
      <CardFooter sx={cardFooter}>
        {infoShown ? 
          <Button 
            rightIcon={<ChevronUpIcon />}
            onClick={() => setInfoShown(false)}
            variant="linkPrimary"
          >
            <Trans>Show Less</Trans>
          </Button>
          :
          <Button
            rightIcon={<ChevronDownIcon />}
            onClick={() => setInfoShown(true)}
            variant="linkPrimary"
          >
            <Trans>Show more</Trans>
          </Button>
        }
      </CardFooter>
    </Card>
  );
}