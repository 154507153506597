export enum ErrorCode {
    UNKNOWN,
    NETWORK_ERROR,
    VALIDATION_ERROR,
    UNAUTHENTICATED,
    EMPTY_INPUT_VALIDATION,
    EMPTY_SELECT_VALIDATION,
    INVALID_BULK_UPLOAD,
    LOGGED_OUT,
    INVALID_PERMISSIONS,
}