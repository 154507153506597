import {Box, Button, Heading, SimpleGrid, VStack} from '@chakra-ui/react';
import {Trans} from 'react-i18next';
import {ReactNode} from 'react';
import {useFormContext} from 'react-hook-form';
import {CreateLicenceType} from '../types';
import {createLicenceStyle} from '../theme/CreateLicenceStyle';
import {TextDark800} from '@RHEnvironmental/pattern-library-tokens';

interface EditLicenceStepLayoutProps {
  header: string;
  children: ReactNode;
  onSubmit: (data:Partial<CreateLicenceType>) => void;
}

export const EditLicenceLayout = ({header, children, onSubmit}:EditLicenceStepLayoutProps) => {
  const {handleSubmit, formState: {isSubmitting, isDirty}} = useFormContext();

  const goBack = () => {
    window.history.back();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{height: '100%'}}>
      <VStack sx={createLicenceStyle}>
        <Heading variant="Lt4" alignSelf='start' color={TextDark800}><Trans>{header}</Trans></Heading>
        {children}
        <SimpleGrid
          gridTemplateColumns='repeat(2, auto)'
          justifyContent="space-between"
          alignItems="end"
          width={'100%'}
        >
          <Box>
            <Button variant="secondary" onClick={goBack}>
              <Trans>Back</Trans>
            </Button>
          </Box>
          <Button type="submit" isDisabled={isSubmitting || !isDirty}>
            <Trans>Save</Trans>
          </Button>
        </SimpleGrid>
      </VStack>
    </form>
  )
}