import {useState} from 'react';

const usePages = () => {
  const [page, setPage] = useState<number>(1);

  const movePage = (delta: number) => () => {
    const newPage = page + delta;

    if (newPage < 1) return;

    setPage(page + delta);
  };

  const decrementPage = movePage(-1);
  const incrementPage = movePage(1);

  return {
    movePage,
    setPage,
    incrementPage,
    decrementPage,
    page,
  };
};

export default usePages;
