import {
  BorderRadius01,
  TextBodyReg4FontFamily,
  TextBodyReg4FontSize,
  TextBodyReg4FontWeight,
  TextBodyReg4LetterSpacing,
  TextBodyReg4LineHeight,
  TextBodyReg4TextCase,
  TextBodyReg4TextDecoration,
  TextDark800,
  TogglesIcon,
} from '@RHEnvironmental/pattern-library-tokens';
  
import {checkboxAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';
import {px} from './px';
import {FallbackFonts} from './fallbackFonts';

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const TOGGLES_SELECTED = '#051441';

const primary = definePartsStyle({
  control: {
    borderRadius: px(BorderRadius01),
    backgroundColor: TogglesIcon,
    _checked: {
      backgroundColor: TOGGLES_SELECTED,
      borderColor: TOGGLES_SELECTED
    }
  },
  label: {
    fontFamily: TextBodyReg4FontFamily+FallbackFonts,
    fontWeight: TextBodyReg4FontWeight,
    lineHeight: px(TextBodyReg4LineHeight),
    fontSize: px(TextBodyReg4FontSize),
    letterSpacing: TextBodyReg4LetterSpacing,
    textTransform: TextBodyReg4TextCase,
    textDecoration: TextBodyReg4TextDecoration,
    color: TextDark800
  }
});

export const Checkbox = defineMultiStyleConfig({
  variants: {
    primary,
  },
  defaultProps: {
    variant: 'primary',
    size: 'lg'
  },
});