import {TextDark800} from '@RHEnvironmental/pattern-library-tokens';
import {Heading} from '@chakra-ui/react';
import {InputsAddress} from '../../components/Inputs/InputsAddress';
import {SPInput} from '../../components/Inputs/SPInput';
import {SPTextarea} from '../../components/Inputs/SPTextarea';
import {Trans} from 'react-i18next';

export const EditCertificatePremisesDetails = () => {
  return (
    <>
      <Heading variant="Lt4" alignSelf='start' color={TextDark800}>
        <Trans>Edit certificate premises details</Trans>
      </Heading>
      <SPInput
        id='certificate_holder_name'
        required={true}
        label='Certificate holder name'
        placeholder='Enter a certificate holder name here'
        errors={{
          required: <Trans>Please enter the certificate holder name</Trans>
        }}
      />
      <SPInput
        id='number_of_workstations'
        required={true}
        label='Number of workstations'
        placeholder='Enter number of workstations here'
        errors={{
          required: <Trans>Please enter the number of workstations</Trans>,
          pattern: {
            value: /^[0-9]+$/,
            message: <Trans>Please enter a valid number</Trans>
          }
        }}
      />
      <InputsAddress
        isAssociated={false}
      />
      <SPTextarea
        id='internal_notes'
        label='Internal notes (optional)'
      />
    </>
  )
}