import React, {useContext} from 'react';
import {Outlet} from 'react-router-dom';
import {Box, Portal, Slide, SimpleGrid} from '@chakra-ui/react';
import {Header} from './Header';
import Footer from './Footer';
import {SearchComponentBackdrop} from '../search/SearchComponentBackdrop';
import {SearchComponent} from '../search/SearchComponent';
import {SearchContext} from '../search/SearchContext';
import {searchPanelStyle} from '../theme/SearchPanelStyle';

const MainLayout = () => {
  const {isSearchPanelOpen} = useContext(SearchContext);

  return (
    <SimpleGrid minHeight='100vh' templateRows='auto 1fr'>
      <Header />
      <Box id='content-start' maxWidth='100vw' h='100%'>
        <Outlet />
      </Box>
      <SearchComponentBackdrop />
      <Portal>
        <Slide direction="left" in={isSearchPanelOpen} style={searchPanelStyle}>
          <SearchComponent />
        </Slide>
      </Portal>
      <Footer />
    </SimpleGrid>
  )
}

export default MainLayout;