import {CanvasDarkGrey} from '@RHEnvironmental/pattern-library-tokens'
import {defineStyle, defineStyleConfig} from '@chakra-ui/react'

const primary = defineStyle({
  borderColor: CanvasDarkGrey,
  borderStyle: 'solid',
  borderWidth: '1px',
})

export const Divider = defineStyleConfig({
  variants: {
    primary
  },
  defaultProps: {
    variant: 'primary',
  }
})