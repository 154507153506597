import {SPInput} from './SPInput';

interface InputsAddressType {
  index?: number;
  isAssociated?: boolean;
}

export const InputsAddress = ({index = 0, isAssociated = true}:InputsAddressType) => {
  return (
    <>
      {isAssociated ? (
        <SPInput
          id={`addresses.${index}.associated_business_name`}
          label='Associated business name'
          placeholder='Enter associated business name here'
        />
      ) : (
        <SPInput
          id='business_name'
          required={true}
          label='Business name'
          placeholder='Enter business name here'
        />
      )}
      <SPInput
        id={`addresses.${index}.address_line1`}
        required={true}
        label='Address line 1'
        placeholder='Enter address line 1 here'
        errors={{
          required: 'Please enter the business address'
        }}
      />
      <SPInput
        id={`addresses.${index}.address_line2`}
        label='Address line 2'
        placeholder='Enter address line 2 here'
      />
      <SPInput
        id={`addresses.${index}.town`}
        required={true}
        label='Town / city'
        placeholder='Enter town / city here'
        errors={{
          required: 'Please enter the business town / city'
        }}
      />
      <SPInput
        id={`addresses.${index}.postcode`}
        required={true}
        label='Postcode'
        placeholder='Enter postcode here'
        errors={{
          required: 'Please enter the business postcode'
        }}
      />
    </>
  )
}