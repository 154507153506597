import {PaginationMeta} from '../api/client.schemas';
import {Button, Box, HStack} from '@chakra-ui/react';
import {Trans, useTranslation} from 'react-i18next';

interface PaginationRowProps {
  pagination: PaginationMeta
  onNext: () => void
  onPrev: () => void
}

export const Pagination = (props: PaginationRowProps) => {
  const {t} = useTranslation();

  return <HStack>
    <Box>
      {t('paginationSummary', {
        page: props.pagination.page,
        totalPages: props.pagination.total_pages,
      })}
    </Box>
    <Box>
      <Button onClick={props.onPrev} isDisabled={props.pagination.page === 1} p={2}>
        <Trans>Previous</Trans>
      </Button>
    </Box>
    <Box>
      <Button onClick={props.onNext} isDisabled={props.pagination.page === props.pagination.total_pages} p={2}>
        <Trans>Next</Trans>
      </Button>
    </Box>
  </HStack>
};