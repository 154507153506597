import {Trans} from 'react-i18next';
import {Divider, Heading} from '@chakra-ui/react';
import {TextDark800} from '@RHEnvironmental/pattern-library-tokens';
import {InputsAddress} from '../../components/Inputs/InputsAddress';
import {InputsAddresses} from '../../components/Inputs/InputsAddresses';
import {SPInput} from '../../components/Inputs/SPInput';
import {SPTextarea} from '../../components/Inputs/SPTextarea';
import {useFormContext} from 'react-hook-form';

export const EditPractitionerDetails = () => {
  const {getValues} = useFormContext();
  const addresses = [];
  for (let i = 1; i < getValues('addresses').length; i ++) {
    addresses.push(
      <>
        <Divider/>
        <InputsAddress key={i} index={i}/>
      </>
    );
  }

  return (
    <>
      <Heading variant="Lt4" alignSelf='start' color={TextDark800}>
        <Trans>Edit practitioner licence details</Trans>
      </Heading>
      <SPInput
        id='practitioner_name'
        required={true}
        label='Practitioner name'
        placeholder='Enter practitioner name'
        errors={{
          required: <Trans>Please enter the practitioner name</Trans>
        }}
      />
      <InputsAddress key={0} index={0}/>
        {...addresses}
        <InputsAddresses />
        <SPTextarea
          id='internal_notes'
          label='Internal notes (optional)'
        />
    </>
  )
}