import {VStack, HStack, Text, Button, Spacer, Heading, Spinner} from '@chakra-ui/react';
import {useSearch} from '../hooks/useSearch';
import {SearchComponentPageBuffer} from '../search/SearchComponentPageBuffer';
import {LicenceCardHandler} from '../components/LicenceCard/LicenceCardHandler';
import {SearchActions} from '../types/search';
import {useSearchParams} from 'react-router-dom';
import {useEffect} from 'react';
import {cardLayout, header, layout} from '../theme/SearchPage';
import {Spacing04} from '@RHEnvironmental/pattern-library-tokens';
import {px} from '../theme/px';
import {Trans} from 'react-i18next';

export const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const {searchResults, dispatchSearchQuery, isLoading, DEFAULT_TAKE, nextQueryStart} = useSearch();

  useEffect(() => {
    dispatchSearchQuery({
      type: SearchActions.READ,
      searchParams: searchParams
    });
  },[searchParams]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <HStack gap={0} justify="stretch" data-testid={'search-page'} height="100%" align="flex-start">
      <SearchComponentPageBuffer />
      <VStack sx={layout} style={{marginInlineStart: '0'}}>
        <Heading id="search-title" as="h1" sx={header} zIndex={{base: '0', md: '2', sm: '1'}} tabIndex={0}>
          <Trans>Search Results</Trans>:
        </Heading>
        {(!!searchResults && searchResults.length !== 0 && !isLoading) ?
          (
            <VStack zIndex={0} sx={cardLayout}>
              {searchResults.map((licence, index) => (
                <LicenceCardHandler key={index} licence={licence}/>
              ))}
              <Spacer/>
              {searchResults.length < DEFAULT_TAKE || searchResults.length < nextQueryStart.current ? (
                <Spacer />
              ) : (
                <Button w={200} alignSelf="center" mt={px(Spacing04)} isLoading={isLoading} onClick={() => dispatchSearchQuery({
                  type: SearchActions.NEXT,
                  searchParams: searchParams,
                })}><Trans>Load More</Trans></Button>
              )}
            </VStack>
          )
          :
          <Text><Trans>No results found</Trans></Text>
        }
      </VStack>
    </HStack>
  )
}