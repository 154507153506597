import {IconMedium600, Spacing03} from '@RHEnvironmental/pattern-library-tokens';
import {HamburgerIcon} from '@chakra-ui/icons';
import {
  Avatar,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid
} from '@chakra-ui/react';
import {useContext} from 'react';
import {AuthenticationContext} from '../login/AuthenticationContext';
import {headerStyle, headings} from '../theme/Header';
import {Trans, useTranslation} from 'react-i18next';
import {SearchContext} from '../search/SearchContext';
import RHELogo from '../images/RHE_logo.png';
import {px} from '../theme/px';
import {Language} from '../types';
import {useLogout} from '../login/useLogout';
import {SkipNavLink} from '@chakra-ui/skip-nav';

export const Header = () => {
  const {isLoggedIn} = useContext(AuthenticationContext);
  const {isSearchPanelOpen, setIsSearchPanelOpen} = useContext(SearchContext);
  const {logout} = useLogout();
  const {i18n} = useTranslation();

  const onLanguageChange = (lang: Language) => {
    sessionStorage.setItem('language', lang);
    i18n.changeLanguage(lang)
  };

  return (
    <>
      <SkipNavLink zIndex={99} id='content-start'>
        <Trans>Skip to content</Trans>
      </SkipNavLink>
      <SimpleGrid sx={headerStyle}>
        <Button variant="link" onClick={() => setIsSearchPanelOpen(!isSearchPanelOpen)} aria-label="Clickable, opens Search Panel.">
          <HamburgerIcon h="24px" w="24px" color={IconMedium600}/>
        </Button>
        <Flex
          sx={headings}
          direction={{base: 'column', md: 'row'}}
          gap={{base: '0', md: px(Spacing03)}}
          align={{base: 'flex-start', md: 'center'}}
        >
          <Heading as="p" variant={{base:'Md6Primary', md:'Md4Primary', sm:'Md6Primary'}}>
            <strong><Trans>SPECIAL PROCEDURES</Trans></strong>
          </Heading>
          <Heading as="p" variant={{base:'Lt6', md:'Lt4', sm:'Lt6'}} fontWeight="200">
            <Trans>PUBLIC REGISTER</Trans>
          </Heading>
        </Flex>
        {isLoggedIn ? (
          <HStack spacing="12px">
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<Avatar size="sm" />}
                variant="solid"
                colorScheme="white"
              />
              <MenuList>
                <MenuItem onClick={logout}><Trans>Sign out</Trans></MenuItem>
              </MenuList>
            </Menu>
            <Image src={RHELogo} alt="RHE logo" />
          </HStack>
        ):(
          i18n.language === Language.en || i18n.language === undefined ? (
            <Button variant='outline' onClick={() => onLanguageChange(Language.cy)} aria-label="Change language to Cymraeg">
              Cymraeg
            </Button>
          ):(
            <Button variant='outline' onClick={() => onLanguageChange(Language.en)} aria-label="Change language to English">
              English
            </Button>
          )
        )}
      </SimpleGrid>
    </>
  )
}
