import {extendTheme} from '@chakra-ui/react';
import {styles} from './theme/styles';
import {textStyles} from './theme/textStyles';
import {layerStyles} from './theme/layerStyles';
import {colors} from './theme/colors';
import {Button} from './theme/Button';
import {Input} from './theme/Input';
import {FormLabel} from './theme/FormLabel';
import {Card} from './theme/Card/Card';
import {Link} from './theme/Link';
import {Heading} from './theme/Heading';
import {Select} from './theme/Select';
import {Checkbox} from './theme/Checkbox';
import {breakpoints} from './theme/breakpoints';
import {Textarea} from './theme/Textarea';
import {Divider} from './theme/Divider';
import {Table} from './theme/Table';

export const theme = extendTheme({
  styles,
  textStyles,
  layerStyles,
  breakpoints,
  components: {
    Button,
    Card,
    Divider,
    Input,
    Heading,
    FormLabel,
    Link,
    Select,
    Checkbox,
    Textarea,
    Table,
  },
  colors,
});