import {Center} from '@chakra-ui/react';
import {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {AuthenticationContext} from '../login/AuthenticationContext';
import {LoginCard} from '../login/LoginCard';

export const LoginPage = () => {
  const {isLoggedIn} = useContext(AuthenticationContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/create-licence');
    }
  }, [isLoggedIn]);

  return <Center data-testid={'login-page'} height="100%">
    <LoginCard  />
  </Center>;
}