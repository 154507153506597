import {SPInput} from './SPInput';
import {SpecificInputType} from '../../types/validation/Input';
import {Trans} from 'react-i18next';

export const InputLicenceNumber = ({id = 'licenceNumber', required = false}:SpecificInputType) => {
  return (
    <SPInput
      id={id}
      required={required}
      label="Licence number"
      placeholder="Enter the licence number"
      errors={{
        required: <Trans>Please enter the licence number</Trans>
      }}
    />
  )
}