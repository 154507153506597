import {SPInput} from '../../components/Inputs/SPInput';
import {SPTextarea} from '../../components/Inputs/SPTextarea';
import {LicenceSubmissionWrapper} from './LicenceSubmissionWrapper';

export const CertificateVehicleDetails = () => {
  return (
    <LicenceSubmissionWrapper header='Enter certificate vehicle details'>
      <SPInput 
        id='business_name'
        required={true}
        label='Business name'
        placeholder='Enter business name here'
      />
      <SPInput 
        id='certificate_holder_name'
        required={true}
        label='Certificate holder name'
        placeholder='Enter a certificate holder name here'
      />
      <SPInput 
        id='vehicle_registration_details'
        required={true}
        label='Vehicle registration details'
        placeholder='Enter vehicle registration details here'
      />
      <SPTextarea 
        id='vehicle_description'
        required={true}
        label='Vehicle description'
        placeholder='Enter vehicle description here'
      />
      <SPTextarea
        id='internal_notes'
        label='Internal notes (optional)'
      />
    </LicenceSubmissionWrapper>
  )
}