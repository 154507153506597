import {VStack} from '@chakra-ui/react';
import {InputIssueDate} from '../components/Inputs/InputIssueDate';
import {SelectLicenceType} from '../components/Inputs/SelectLicenceType';
import {SelectProcedureTypes} from '../components/Inputs/SelectProcedureTypes';
import {InputLicenceNumber} from '../components/Inputs/InputLicenceNumber';
import {EditLicenceDetails} from './EditLicenceDetails';
import {EditLicenceSubmissionWrapper} from './edit_details/EditLicenceSubmissionWrapper';
import {InputArchivedDate} from '../components/Inputs/InputArchivedDate';
import {useForm, FormProvider} from 'react-hook-form';
import {EditLicence} from '../types';
import {pluck} from 'ramda';
import {Licence} from '../api/client.schemas';

interface EditLicenceComponentProps {
  licence: Licence;
}

export const EditLicenceComponent = (props: EditLicenceComponentProps) => {
  const methods = useForm<EditLicence>({
    defaultValues: {
      ...props.licence,
      archived_date: props.licence.archived_date ?? undefined,
      issue_date: new Date(props.licence.issue_date).toISOString().slice(0, 10),
      procedure_type_ids: pluck('procedure_type_id', props.licence.procedure_types)
    }
  });

  return (
    <VStack w='100%'>
      <FormProvider {...methods}>
        <EditLicenceSubmissionWrapper header={'Edit licence'}>
          <SelectLicenceType disabled id='licence_type_id' required />
          <InputLicenceNumber id='licence_number' required/>
          <SelectProcedureTypes id='procedure_type_ids' required/>
          <InputIssueDate width='50%' id='issue_date' required/>
          <InputArchivedDate width='50%' id='archived_date'/>
          <EditLicenceDetails />
        </EditLicenceSubmissionWrapper>
      </FormProvider>
    </VStack>
  )
}
