import {useTranslation} from 'react-i18next';
import {isLanguage} from '../types/guards/isLanguage';
import {Language} from '../types';

export const useLanguage = ():Language => {
  const DEFAULT_LANGUAGE:Language = Language.en;
  const {i18n} = useTranslation();
  if(isLanguage(i18n.resolvedLanguage)){
    return i18n.resolvedLanguage;
  }
  return DEFAULT_LANGUAGE;
}