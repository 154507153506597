import {ReactNode} from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
} from '@chakra-ui/react';
import {Trans} from 'react-i18next';

export const ModalConfirm = ({title, children, callback, isOpen, onClose}:{title:string, children: ReactNode[], callback: () => void, isOpen: boolean, onClose: () => void}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader><Trans>{title}</Trans></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={callback}><Trans>Confirm</Trans></Button>
          <Button variant="secondary" onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};