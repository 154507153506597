import {Address} from '../../types';

export const strFromAddress = (address:Address, includeBusinessName=false):string => {
  let outputString = '';
  if(!address){
    return '';
  }
  if(address.associated_business_name && includeBusinessName){
    outputString += `${address.associated_business_name}`;
  }
  outputString += `${address.address_line1}, `;
  if(address.address_line2){
    outputString += `${address.address_line2}, `;
  }
  outputString += `${address.town}, `;
  outputString += address.postcode;
  return outputString;
}