import {BorderWidth01, Box2DownMediumBlur, Box2DownMediumColor, Box2DownMediumSpread, Box2DownMediumX, Box2DownMediumY, Spacing04, Spacing05, Spacing06, TextWhite100} from '@RHEnvironmental/pattern-library-tokens';
import {makeBoxShadow} from '../makeBoxShadow';
import {px} from '../px';
import {figmaColors} from '../colors';

const SEARCH_HEADER_HEIGHT = '57px';

export const layout = {
  align: 'stretch',
  width: '100%',
  overflowY: 'auto',
  height: '100%',
}

export const header = {
  position: 'sticky',
  top: '0',
  width: '100%',
  height: SEARCH_HEADER_HEIGHT,
  color: TextWhite100,
  backgroundColor: figmaColors['base1-500'],
  padding: `${px(Spacing04)} ${px(Spacing05)}`,
  borderBottomWidth: BorderWidth01,
  boxShadow: makeBoxShadow(Box2DownMediumX, Box2DownMediumY, Box2DownMediumBlur, Box2DownMediumSpread, Box2DownMediumColor),
}

export const cardLayout = {
  px: px(Spacing06),
  py: px(Spacing04),
  gap: px(Spacing04),
  alignItems: 'stretch',
  width: '100%',
}