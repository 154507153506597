import {QueryClient} from '@tanstack/react-query';
import {getGetLicenceTypesQueryKey, getGetLocalAuthoritiesQueryKey, getGetProcedureTypesQueryKey} from './client';

const permCacheOptions = {
  cacheTime: Infinity,
  staleTime: Infinity,
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  }
});

client.setQueryDefaults(getGetLocalAuthoritiesQueryKey(), permCacheOptions);
client.setQueryDefaults(getGetProcedureTypesQueryKey(), permCacheOptions);
client.setQueryDefaults(getGetLicenceTypesQueryKey(), permCacheOptions);

export const queryClient = client;