import {Box, Button, Checkbox, FormControl, FormLabel, Heading, Input, InputGroup,InputRightElement, Spacer, VStack} from '@chakra-ui/react';
import {useContext, useState} from 'react';
import {useWindowSize} from 'react-use';
import {Trans, useTranslation} from 'react-i18next';
import {SearchIcon} from '../icons/searchIcon';
import {FormProvider, useForm} from 'react-hook-form';
import {Search} from '../types';
import {useNavigate} from 'react-router-dom';
import {SearchContext} from './SearchContext';
import {AuthenticationContext} from '../login/AuthenticationContext';
import {searchComponentStyle} from '../theme/SearchPanelStyle';
import {SelectLicenceType} from '../components/Inputs/SelectLicenceType';
import {SelectLocalAuthority} from '../components/Inputs/SelectLocalAuthority';
import {SelectProcedureTypes} from '../components/Inputs/SelectProcedureTypes';
import {breakpoints} from '../theme/breakpoints';

interface SearchInputs {
  searchTerm: string;
  localAuthority: string;
  procedureTypes: string[];
  licenceType: string;
  showArchived: string;
}

export const SearchComponent = () => {
  const {isSearchPanelOpen} = useContext(SearchContext);
  const methods = useForm<SearchInputs>();
  const {register, handleSubmit} = methods;
  const {isLoggedIn} = useContext(AuthenticationContext);
  const [hasSearched, setHasSearched] = useState(false);
  const {setIsSearchPanelOpen} = useContext(SearchContext);
  const navigate = useNavigate();
  const {width} = useWindowSize();
  const {t} = useTranslation();

  const focusTitle = () => {
    const title = document.getElementById('search-title');
    if (title) {
      title.focus();
    }
  }

  const onSubmit = (data:SearchInputs) => {
    if (width <= parseInt(breakpoints.sm)) {
      setIsSearchPanelOpen(false);
    }
    setHasSearched(true);
    const normalisedData:Search = {};
    let key: keyof SearchInputs;
    for (key in data) {
      const value = data[key];
      if (!value || value.length === 0) {
        continue;
      }
      if (Array.isArray(value)) {
        normalisedData[key] = value.join();
        continue;
      }
      normalisedData[key] = value;
    }
    const params = new URLSearchParams(normalisedData as Record<string, string>);
    focusTitle();
    navigate(`/search?${params.toString()}`);
  }

  return (
    <FormProvider {...methods}>
      <form style={{height: '100%'}} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{
          ...searchComponentStyle,
          '@media(min-height:836px)': {
            overflowY: 'hidden',
          },
        }}>
          <VStack w="320px" align="flex-start" spacing="24px" p="16px" pb="40px">
            <Button
              variant="link"
              alignSelf="flex-end"
              onClick={() => setIsSearchPanelOpen(false)}
              aria-label={t('Clickable close Search panel')}
            >
              <Trans>Close</Trans>
            </Button>
            <FormControl w='100%'>
              <FormLabel variant={'primary'} htmlFor={'search-form-search'}>
                <Trans>Search for business or practitioner name</Trans>
              </FormLabel>
              <InputGroup>
                <Input
                  id={'search-form-search'}
                  {...register('searchTerm')}
                  type={'text'}
                  data-testid={'search-form__search-field'}
                  defaultValue={''}
                  placeholder={t('Enter search text')}
                />
                <InputRightElement py="16px">
                  <SearchIcon />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Heading as="h2" variant="Lt4Primary">
              <Trans>Filter</Trans>
            </Heading>
            <SelectLocalAuthority enabled={isSearchPanelOpen} />
            <SelectProcedureTypes enabled={isSearchPanelOpen} />
            <SelectLicenceType enabled={isSearchPanelOpen} />
            {isLoggedIn &&
              <Checkbox {...register('showArchived')} value={'1'}>
                <Trans>Show archived</Trans>
              </Checkbox>
            }
            <Spacer />
            <Button
              type="submit"
              variant={'primary'}
              data-testid={'search-form__submit-button'}
              w="100%"
              aria-label={hasSearched ? t('Apply search filter') : t('Search licences')}
            >
              <Trans>{hasSearched ? t('Apply') : t('Search')}</Trans>
            </Button>
          </VStack>
        </Box>
      </form>
    </FormProvider>
  )
}
