import * as mappers from './mappers';
import {keys} from 'ramda';
import {ApplicationError} from './ApplicationError';
import {ErrorCode} from './ErrorCode';

export const convertToAppError = (error: Error) => {
  let i = 0;
  let applicationError: ApplicationError | undefined = undefined;
  const mapperKeys = keys(mappers);

  while (i < mapperKeys.length) {
    const key = mapperKeys[i];
    applicationError = mappers[key](error);

    if (applicationError) {
      return applicationError;
    }

    i ++
  }

  return new ApplicationError('Unknown Error', ErrorCode.UNKNOWN);
}