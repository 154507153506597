import {Dispatch, SetStateAction, createContext} from 'react';

interface ISearchContext {
  isSearchPanelOpen: boolean;
  setIsSearchPanelOpen: Dispatch<SetStateAction<boolean>>
}

export const SearchContext = createContext<ISearchContext>({
  isSearchPanelOpen: false,
  setIsSearchPanelOpen: () => {},
});