import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import en from './locales/en.json';
import cy from './locales/cy.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const commonOptions = {
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.LANG_DEBUG === 'true',
  returnNull: false,
};

if (process.env.LANG_SERVER_ENABLED === 'true') {
  i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      ...commonOptions,
      saveMissing: true,
      saveMissingTo: 'all',
      backend: {
        loadPath: process.env.LANG_SERVER_LOAD_PATH,
        addPath: process.env.LANG_SERVER_ADD_PATH,
      },
    });
} else {
  i18n
    .use(initReactI18next)
    .init({
      ...commonOptions,
      resources: {
        en: {
          translation: en,
        },
        cy: {
          translation: cy,
        },
      },
    });
}
