import {useEffect, useState} from 'react';
import {Outlet, redirect} from 'react-router-dom';
import {useCookie, useEffectOnce} from 'react-use';
import {AuthenticationContext} from './AuthenticationContext';
import {authenticationEmitter} from './authenticationEmitter';
import Cookies from 'universal-cookie';
import {User} from '../types/User';

const cookie = new Cookies();

export const AuthenticationContextProvider = () => {
  const [token, setToken] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [storedToken, updateStoredToken, deleteStoredToken] = useCookie('token');
  const [storedUser, updateStoredUser, deleteStoredUser] = useCookie('user');

  const onUnauthenticated = () => {
    deleteStoredToken();
    deleteStoredUser();
    setToken('');
    setIsLoggedIn(false);
    redirect('/login');
    cookie.remove('token');
  };

  useEffect(() => {
    authenticationEmitter.on('unauthenticated', onUnauthenticated);

    return () => {
      authenticationEmitter.off('unauthenticated', onUnauthenticated);
    };
  }, [authenticationEmitter, onUnauthenticated]);

  useEffectOnce(() => {
    if (storedToken && !token) {
      setToken(storedToken);
      setIsLoggedIn(true);
      if (storedUser) {
        const user = JSON.parse(storedUser);
        setUser(user);
      }
    } else if (token) {
      deleteStoredToken();
      deleteStoredUser();
    }
  });

  const setTokenProxy = (token: string) => {
    updateStoredToken(token, {
      secure: true,
    });
    setToken(token);
  };

  const setUserProxy = (user: User | undefined) => {
    if (user) {
      updateStoredUser(JSON.stringify(user), {
        secure: true,
      });
    } else {
      deleteStoredUser();
    }

    setUser(user);
  }

  return <AuthenticationContext.Provider value={{token, setToken: setTokenProxy, isLoggedIn, setIsLoggedIn, user, setUser: setUserProxy}}>
    <Outlet />
  </AuthenticationContext.Provider>
}
