import Axios, {AxiosRequestConfig, isAxiosError} from 'axios';
import {makeAxios} from '../makeAxios';
import Cookies from 'universal-cookie';
import {ApplicationError} from '../../error/ApplicationError';
import {convertToAppError} from '../../error/convertToAppError';

const cookie = new Cookies();

export const destructor = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const axios = makeAxios(cookie.get('token') ?? undefined);
  const source = Axios.CancelToken.source();
  try {
    const response = await axios.request({
      ...config,
      ...options,
      cancelToken: source.token,
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw convertToAppError(error);
    }

    throw new Error('Unknown error');
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ErrorType<Error> = ApplicationError;