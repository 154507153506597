import {Input} from '@chakra-ui/react';
import {ErrorCode} from '../../error/ErrorCode';
import {errorMessages} from '../../error/errorMessages';
import {InputWrapper} from './InputWrapper';
import {GenericInputType} from '../../types/validation/Input';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

export const SPInput = ({id, required, label, placeholder = '', errors}:GenericInputType) => {
  const {register} = useFormContext();
  const {t} = useTranslation();

  const DEFAULT_ERRORS = {
    required: (required ? errorMessages[ErrorCode.EMPTY_INPUT_VALIDATION] : false)
  };

  const ERRORS = !errors ? DEFAULT_ERRORS : errors;

  return (
    <InputWrapper id={id} required={required} label={t(label)}>
      <Input
        {...register(id, ERRORS)}
        placeholder={t(placeholder) || placeholder}
      />
    </InputWrapper>
  )
}