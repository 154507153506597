import {defineStyleConfig} from '@chakra-ui/react';
import * as tokens from '@RHEnvironmental/pattern-library-tokens';

export const Table = defineStyleConfig({
  variants: {
    rounded: {
      table: {
        background: tokens.CanvasTableHeader,
        borderRadius: '8px',
        '-webkit-tap-highlight-color': 'transparent',
      },
      thead: {
        background: tokens.CanvasTableHeader,
        borderBottomColor: tokens.BorderMedium600,
        borderBottomWidth: tokens.BorderWidth01,
      },
      tr: {
        borderBottomColor: tokens.BorderLight500,
        borderBottomWidth: tokens.BorderWidth01,
        fontSize: tokens.TextBodyReg4FontSize,
        color: tokens.TextMedium600,
        background: tokens.CanvasTableRowLight,
        _odd: {
          background: tokens.CanvasTableRowDark,
        },
      },
      td: {
        py: 3,
        px: 3,
      },
      th: {
        textTransform: 'none',
        fontSize: tokens.TextBodyMedium4FontSize,
        fontWeight: tokens.TextBodyMedium4FontWeight,
        color: tokens.TextBlack800,
        background: tokens.CanvasTableHeader,
        letterSpacing: 'normal',
        py: 5,
        px: 3,
      },
    }
  }
});