import {CertificatePremisesLicence} from '../../types';
import {CardInfo} from './CardInfo';
import {LicenceCard} from './LicenceCard';
import {strFromAddress} from './dataHandling';

export const CertificatePremisesLicenceCard = ({licence}: {licence:CertificatePremisesLicence}) => {
  const {
    business_name,
    certificate_holder_name,
    addresses,
    number_of_workstations,
  } = licence;

  const strAddress = strFromAddress(addresses[0]);

  return (
    <LicenceCard licence={licence} header={business_name}>
      <CardInfo title="Licence number" value={licence.licence_number} />
      <CardInfo title="Certificate holder name" value={certificate_holder_name} />
      <CardInfo title="Approved premises address" value={strAddress} />
      <CardInfo title="Number of workstations" value={number_of_workstations.toString()} />
    </LicenceCard>
  );
}
