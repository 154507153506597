import {TextDark800} from '@RHEnvironmental/pattern-library-tokens';
import {Heading} from '@chakra-ui/react';
import {SPInput} from '../../components/Inputs/SPInput';
import {SPTextarea} from '../../components/Inputs/SPTextarea';
import {Trans} from 'react-i18next';

export const EditCertificateVehicleDetails = () => {
  return (
    <>
      <Heading variant="Lt4" alignSelf='start' color={TextDark800}>
        <Trans>Edit certificate vehicle details</Trans>
      </Heading>
      <SPInput 
        id='business_name'
        required={true}
        label='Business name'
        placeholder='Enter business name here'
        errors={{
          required: <Trans>Please enter the business name</Trans>
        }}
      />
      <SPInput 
        id='certificate_holder_name'
        required={true}
        label='Certificate holder name'
        placeholder='Enter a certificate holder name here'
        errors={{
          required: <Trans>Please enter the certificate holder name</Trans>
        }}
      />
      <SPInput 
        id='vehicle_registration_details'
        required={true}
        label='Vehicle registration details'
        placeholder='Enter vehicle registration details here'
        errors={{
          required: <Trans>Please enter the vehicle registration</Trans>
        }}
      />
      <SPTextarea 
        id='vehicle_description'
        required={true}
        label='Vehicle description'
        placeholder='Enter vehicle description here'
        errors={{
          required: <Trans>Please enter the vehicle details</Trans>
        }}
      />
      <SPTextarea
        id='internal_notes'
        label='Internal notes (optional)'
      />
    </>
  )
}