import {Box, Card, CardHeader, CardBody, Stack} from '@chakra-ui/react'
import {Spacing04, Spacing05} from '@RHEnvironmental/pattern-library-tokens';
import {Trans} from 'react-i18next';
import {AppForm} from '../components/AppForm';
import {AppFormError} from '../components/AppFormError';
import {LoginForm} from './LoginForm';
import {px} from '../theme/px';

const cardTextStyles = {
  paddingTop: px(Spacing04),
  paddingLeft: px(Spacing05)
}

export const LoginCard = () => {
  return <Stack align={'center'}>
    <AppForm>
      <Card w="400px">
        <CardHeader>
          <Box as="h1" tabIndex={0} sx={cardTextStyles} data-testid='login-page__sign-in-title'>
            <Trans>Sign in</Trans>
          </Box>
        </CardHeader>
        <AppFormError />
        <CardBody>
          <Box data-testid='login-page__login-card'>
            <LoginForm  />
          </Box>
        </CardBody>
      </Card>
    </AppForm>
  </Stack>
}