import {useContext, useEffect} from 'react';
import {ValidationError} from '../api/validation/ValidationError';
import {NestedComponentErrorContext} from '../components/NestedComponentErrorContext';
import {ApplicationError} from '../error/ApplicationError';

interface UseAppFormOptions {
  error: ApplicationError | undefined | ValidationError
}

export const useAppForm = (options: UseAppFormOptions) => {
  const {error, setError} = useContext(NestedComponentErrorContext);

  useEffect(() => {
    setError(options.error);
  }, [options.error]);

  return {
    error,
    setError,
  };
};