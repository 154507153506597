import axios, {AxiosInstance, RawAxiosRequestHeaders} from 'axios';

export const makeAxios = (token?: string): AxiosInstance => {
  const headers: RawAxiosRequestHeaders = {};

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  return axios.create({
    baseURL: process.env.API_BASE_URL,
    headers,
  });
}