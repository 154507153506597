import {useContext} from 'react';
import {AuthenticationContext} from './AuthenticationContext';
import {useLogoutActiveUser} from '../api/client';
import {useToast} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

export const useLogout = () => {
  const {setToken, setIsLoggedIn, setUser} = useContext(AuthenticationContext);
  const {mutate, isLoading, error, isError} = useLogoutActiveUser();
  const toast = useToast();
  const {t} = useTranslation();

  const logout = () => {
    mutate(undefined, {
      onSuccess: () => {
        setToken('');
        setUser(undefined);
        setIsLoggedIn(false);
      },
      onError: () => {
        toast({
          title: t('Unable to logout at this time'),
          status: 'error',
        });
      }
    });
  };

  return {
    logout,
    isLoading,
    isError,
    error,
  };
}
