import {useCallback, useContext} from 'react';
import {AuthenticationContext} from '../../login/AuthenticationContext';

export const usePermissions = () => {
  const {user} = useContext(AuthenticationContext);
  const permissions = user?.permissions ?? [];

  const hasPermission = useCallback((permission: string) => {
    return permissions.includes(permission);
  }, [permissions]);

  return {
    hasPermission,
  };
};