import {Box, Text, Stack, HStack} from '@chakra-ui/react';
import {PropsWithChildren} from 'react';
import {InformationIcon} from '../icons/informationIcon';
import {Trans} from 'react-i18next';

export const ModalAlert = (props: PropsWithChildren) => {
  return <Box layerStyle={'primaryModalAlert'} data-testid={'modal-alert'}>
    <Stack>
      <HStack color='error.icon'>
        <InformationIcon />
        <Text textStyle='textBodyMedium3' color='error.text'>
          <Trans>There is a problem</Trans>
        </Text>
      </HStack>
      <Box>{props.children}</Box>
    </Stack>
  </Box>
}