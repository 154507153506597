import {Box, Container, Heading, Select, Textarea, useToast, VStack, Spacer, Button, HStack} from '@chakra-ui/react';
import {Trans, useTranslation} from 'react-i18next';
import {usePostBulkUpload} from '../api/client';
import {ChangeEvent, useState} from 'react';
import {BulkUploadType} from '../bulkUpload/BulkUploadType';
import {useApplicationErrorMessage} from '../error/useApplicationErrorMessage';
import {PermissionsGuard} from '../admin/permissions/PermissionsGuard';
import {PERMISSION_LICENCE_BULK_CREATE} from '../admin/permissions/Permissions';
import {Link} from 'react-router-dom';

export const BulkUploadPage = () => {
  const {t} = useTranslation();
  const {get: getErrorMessage} = useApplicationErrorMessage();
  const [type, setType] = useState<BulkUploadType>(BulkUploadType.PREMIS);
  const [records, setRecords] = useState<string>('')
  const {mutate, isLoading} = usePostBulkUpload();
  const toast = useToast();

  const onChangeType = (event: ChangeEvent<HTMLSelectElement>) => {
    setType(event.target.value as BulkUploadType);
  }

  const handleRecordsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setRecords(event.target.value);
  };

  const onSubmit = () => {
    if (records.length > 0) {
      mutate({
        data: {
          records: records,
          type,
        }
      }, {
        onSuccess: () => {
          setRecords('');
          toast({
            title: t('Success'),
            description: t('Bulk upload successful'),
            status: 'success',
            duration: 5000,
          })
        },
        onError: (error) => {
          toast({
            title: t('Error'),
            description: getErrorMessage(error),
            status: 'error',
            duration: 10000,
          })
        },
      });
    }
  };

  return <PermissionsGuard permission={PERMISSION_LICENCE_BULK_CREATE}>
    <VStack mt={'40px'} spacing={'20px'} gap={'40px'}>
      <Container width={'540px'} maxWidth={'75%'}>
        <Heading as={'h2'} variant={'Lt4'}>
          <Trans>Bulk Upload</Trans>
        </Heading>
      </Container>
      <Container width={'540px'} maxWidth={'75%'} >
        <Select onChange={onChangeType} width={'100%'}>
          <option value={BulkUploadType.PREMIS} selected={type === BulkUploadType.PREMIS}>
            <Trans>Premises</Trans>
          </option>
          <option value={BulkUploadType.PRACTITIONER} selected={type === BulkUploadType.PRACTITIONER}>
            <Trans>Practitioner</Trans>
          </option>
          <option value={BulkUploadType.VEHICLE} selected={type === BulkUploadType.VEHICLE}>
            <Trans>Vehicle</Trans>
          </option>
        </Select>
      </Container>
      <Container maxWidth={'540px'} >
        <Textarea value={records} onChange={handleRecordsChange} data-testid={'bulk-upload-page__records'} />
      </Container>
      <Container maxWidth={'540px'} >
        <HStack>
          <Box>
            <Link to={'/create-licence'}>
              <Button variant={'secondary'}>
                <Trans>Create Single</Trans>
              </Button>
            </Link>
          </Box>
          <Spacer />
          <Box alignSelf={'flex-end'}>
            <Button isLoading={isLoading} onClick={onSubmit} width={'100%'} data-testid={'bulk-upload-page__submit'}>
              <Trans>
                Submit
              </Trans>
            </Button>
          </Box>
        </HStack>
      </Container>
    </VStack>
  </PermissionsGuard>
};