import {Box} from '@chakra-ui/react';
import {px} from '../theme/px';
import {useWindowSize} from 'react-use';
import {useContext} from 'react';
import {SearchContext} from './SearchContext';
import {breakpoints} from '../theme/breakpoints';

export const SearchComponentPageBuffer = () => {
  const {isSearchPanelOpen} = useContext(SearchContext);
  const {width} = useWindowSize();

  const getBufferWidth = (width:number):number => {
    if(width <= parseInt(breakpoints.md) || !isSearchPanelOpen){
      return 0;
    }
    return 320;
  }

  return <Box w={px(getBufferWidth(width))} transition="width 0.3s linear" flexShrink="0"></Box>;
}