import {Box} from '@chakra-ui/react';
import {searchBackdrop, searchBackdropLg} from '../theme/SearchBackdrop';
import {useWindowSize} from 'react-use';
import {breakpoints} from '../theme/breakpoints';
import {useContext} from 'react';
import {SearchContext} from './SearchContext';

export const SearchComponentBackdrop = () => {
  const {isSearchPanelOpen, setIsSearchPanelOpen} = useContext(SearchContext);
  const {width} = useWindowSize();

  if(isSearchPanelOpen){
    return <Box onClick={() => setIsSearchPanelOpen(false)} sx={width <= parseInt(breakpoints.md) ? searchBackdrop : searchBackdropLg}></Box>;
  }

  return <></>;
}