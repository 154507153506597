import {
  TextHeadingLight4FontFamily,
  TextHeadingLight4FontWeight,
  TextHeadingLight4FontSize,
  TextHeadingLight4LetterSpacing,
  TextHeadingLight4TextDecoration,
  TextHeadingLight4LineHeight,
  TextHeadingLight4TextCase,
  TextHeadingMedium4FontFamily,
  TextHeadingMedium4FontWeight,
  TextHeadingMedium4FontSize,
  TextHeadingMedium4LetterSpacing,
  TextHeadingMedium4TextDecoration,
  TextHeadingMedium4LineHeight,
  TextHeadingMedium4TextCase,
  TextHeadingLight6FontFamily,
  TextHeadingLight6FontSize,
  TextHeadingLight6FontWeight,
  TextHeadingLight6LetterSpacing,
  TextHeadingLight6LineHeight,
  TextHeadingLight6TextCase,
  TextHeadingLight6TextDecoration,
  TextHeadingMedium6FontFamily,
  TextHeadingMedium6FontSize,
  TextHeadingMedium6FontWeight,
  TextHeadingMedium6LetterSpacing,
  TextHeadingMedium6LineHeight,
  TextHeadingMedium6TextCase,
  TextHeadingMedium6TextDecoration,
  TextBodyReg4FontSize,
  TextHeadingMedium2FontFamily,
  TextHeadingMedium2FontWeight,
  TextHeadingMedium2FontSize,
  TextHeadingMedium2LetterSpacing,
  TextHeadingMedium2TextDecoration,
  TextHeadingMedium2LineHeight,
  TextHeadingMedium2TextCase,
} from '@RHEnvironmental/pattern-library-tokens';
import {defineStyle} from '@chakra-ui/react';
import {FallbackFonts} from './fallbackFonts';
import {px} from './px';
import {figmaColors} from './colors';
import {breakpoints} from './breakpoints';

const Lt4 = defineStyle({
  fontFamily: TextHeadingLight4FontFamily + FallbackFonts,
  fontWeight: TextHeadingLight4FontWeight,
  fontSize: px(TextHeadingLight4FontSize),
  letterSpacing: TextHeadingLight4LetterSpacing,
  textDecoration: TextHeadingLight4TextDecoration,
  lineHeight: px(TextHeadingLight4LineHeight),
  textTransform: TextHeadingLight4TextCase,
  color: figmaColors['neutral-500']
})

const Lt4Primary = defineStyle({
  fontFamily: TextHeadingLight4FontFamily + FallbackFonts,
  fontWeight: TextHeadingLight4FontWeight,
  fontSize: px(TextHeadingLight4FontSize),
  letterSpacing: TextHeadingLight4LetterSpacing,
  textDecoration: TextHeadingLight4TextDecoration,
  lineHeight: px(TextHeadingLight4LineHeight),
  textTransform: TextHeadingLight4TextCase,
  color: figmaColors['base1-500']
})

const Md2Primary = defineStyle({
  fontFamily: TextHeadingMedium2FontFamily+FallbackFonts,
  fontWeight: TextHeadingMedium2FontWeight,
  fontSize: px(TextHeadingMedium2FontSize),
  letterSpacing: TextHeadingMedium2LetterSpacing,
  textDecoration: TextHeadingMedium2TextDecoration,
  lineHeight: px(TextHeadingMedium2LineHeight),
  textTransform: TextHeadingMedium2TextCase,
  color: figmaColors['base1-500'],
})

const Md4Primary = defineStyle({
  fontFamily: TextHeadingMedium4FontFamily + FallbackFonts,
  fontWeight: px(TextHeadingMedium4FontWeight),
  fontSize: px(TextHeadingMedium4FontSize),
  letterSpacing: TextHeadingMedium4LetterSpacing,
  textDecoration: TextHeadingMedium4TextDecoration,
  lineHeight: px(TextHeadingMedium4LineHeight),
  textTransform: TextHeadingMedium4TextCase,
  color: figmaColors['base1-500'],
})

const Lt6 = defineStyle({
  fontFamily: TextHeadingLight6FontFamily + FallbackFonts,
  fontWeight: TextHeadingLight6FontWeight,
  fontSize: breakpoints.sm ? px(TextBodyReg4FontSize) : px(TextHeadingLight6FontSize),
  letterSpacing: TextHeadingLight6LetterSpacing,
  textDecoration: TextHeadingLight6TextDecoration,
  lineHeight: px(TextHeadingLight6LineHeight),
  textTransform: TextHeadingLight6TextCase,
  color: figmaColors['neutral-500']
})

const Lt6Primary = defineStyle({
  fontFamily: TextHeadingLight6FontFamily + FallbackFonts,
  fontWeight: TextHeadingLight6FontWeight,
  fontSize: px(TextHeadingLight6FontSize),
  letterSpacing: TextHeadingLight6LetterSpacing,
  textDecoration: TextHeadingLight6TextDecoration,
  lineHeight: px(TextHeadingLight6LineHeight),
  textTransform: TextHeadingLight6TextCase,
  color: figmaColors['base1-500']
})

const Md6Primary = defineStyle({
  fontFamily: TextHeadingMedium6FontFamily + FallbackFonts,
  fontWeight: TextHeadingMedium6FontWeight,
  fontSize: breakpoints.sm ? px(TextBodyReg4FontSize) : px(TextHeadingMedium6FontSize),
  letterSpacing: TextHeadingMedium6LetterSpacing,
  textDecoration: TextHeadingMedium6TextDecoration,
  lineHeight: px(TextHeadingMedium6LineHeight),
  textTransform: TextHeadingMedium6TextCase,
  color: figmaColors['base1-500']
})

export const Heading = defineStyle({
  variants: {
    Lt4,
    Lt4Primary,
    Md2Primary,
    Md4Primary,
    Lt6,
    Lt6Primary,
    Md6Primary
  },
  defaultProps: {
    variant: 'Lt4Primary'
  }
});