import {Input} from '@chakra-ui/react';
import {useFormContext} from 'react-hook-form';
import {SpecificInputType} from '../../types/validation/Input';
import {InputWrapper} from './InputWrapper';

export const InputArchivedDate = ({id = 'issueDate', width}:SpecificInputType) => {
  const {
    register,
  } = useFormContext();

  return (
    <InputWrapper id={id} label="Archived date">
      <Input
        w={width ?? '100%'}
        placeholder="Select archived date"
        {...register(id)}
        type="date"
      />
    </InputWrapper>
  )
}