import {useContext} from 'react';
import {ApplicationErrorText} from '../error/ApplicationErrorText';
import {ModalAlert} from './ModalAlert';
import {NestedComponentErrorContext} from './NestedComponentErrorContext';

export const AppFormError = () => {
  const {error} = useContext(NestedComponentErrorContext);

  return (error) ? <ModalAlert>
    <ApplicationErrorText error={error} />
  </ModalAlert> : null
}