import {Input} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import {SpecificInputType} from '../../types/validation/Input';
import {InputWrapper} from './InputWrapper';

export const InputIssueDate = ({id = 'issueDate', required = false, width}:SpecificInputType) => {
  const {t} = useTranslation();
  const {
    register,
  } = useFormContext();
  return (
    <InputWrapper id={id} label="Issue date" required={required}>
      <Input
        w={width ?? '100%'}
        placeholder="Select issue date"
        {...register(id, {
          required: (required ? t<string>('Please enter the issue date of the licence') : false),
        })}
        type="date"
      />
    </InputWrapper>
  )
}