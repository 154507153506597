import {useContext, useEffect, useState} from 'react';
import {makeAxios} from '../api/makeAxios';
import {AnyLicence} from '../types';
import {AuthenticationContext} from '../login/AuthenticationContext';
import {useLanguage} from './useLanguage';
const axios = makeAxios();

export const useQuery = (initParams?:Record<string, string>) => {
  const language = useLanguage();
  const {token} = useContext(AuthenticationContext);
  const [searchParams, setSearchParams] = useState<Record<string, string>>(initParams || {});
  const [results, setResults] = useState<AnyLicence[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);
    axios.get('/licences', {
      headers: {'Authorization': `Bearer ${token}`},
      params: {...searchParams, language},
      signal: controller.signal
    })
      .then(res => setResults([...res.data.results]))
      .catch(error => setError(error instanceof Error ? error : new Error('An unknown error occurred')))
      .finally(() => setIsLoading(false));
    return () => controller.abort();
  }, [searchParams]);

  return {results, setSearchParams, error, isLoading};
}