import {AxiosError} from 'axios';
import {has, isNil} from 'ramda';
import {ApplicationError} from './ApplicationError';
import {ErrorCode} from './ErrorCode';
import {ApplicationErrorCode} from './ApplicationErrorCode';

const map = new Map<ApplicationErrorCode, ErrorCode>;
map.set(ApplicationErrorCode.INVALID_BULK_UPLOAD_LICENSES, ErrorCode.INVALID_BULK_UPLOAD);
map.set(ApplicationErrorCode.INVALID_PERMISSIONS, ErrorCode.INVALID_PERMISSIONS);

export const makeGenericApplicationError = (error: AxiosError) => {
  if (!isNil(error.response) && has('errorCode', error.response.data) && typeof error.response.data.errorCode == 'number') {
    const code = map.get(error.response.data.errorCode);
    if (code) {
      return new ApplicationError('Generic application error', code)
    }
  }

  return new ApplicationError('Unknown Error', ErrorCode.UNKNOWN);
};