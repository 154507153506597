import {CertificateVehicleLicence} from '../../types';
import {CardInfo} from './CardInfo';
import {LicenceCard} from './LicenceCard';

export const CertificateVehicleLicenceCard = ({licence}: {licence:CertificateVehicleLicence}) => {
  const {
    business_name,
    certificate_holder_name,
    vehicle_registration_details,
    vehicle_description,
  } = licence;
  return (
    <LicenceCard licence={licence} header={business_name}>
      <CardInfo title="Licence number" value={licence.licence_number} />
      <CardInfo title="Certificate holder name" value={certificate_holder_name} />
      <CardInfo title="Vehicle registration details" value={vehicle_registration_details} />
      <CardInfo title="Vehicle details" value={vehicle_description} />
    </LicenceCard>
  );
}
