import React from 'react';

const RheLogoVector = () => {
  return (
    <svg viewBox="0 0 2592 864" width="100%">
      <defs>
        <style>
          {'.cls-1 { opacity: .5;}'}
          {'.cls-1, .cls-2 { fill: #fff; }'}
          {'.cls-3 { fill: #231f20; opacity: 0; }'}
        </style>
      </defs>
      <g id="outline">
        <rect className="cls-3" x="144" y="144" width="2304" height="576"/>
      </g>
      <g id="matrix">
        <circle className="cls-1" cx="180" cy="180" r="21"/>
        <circle className="cls-1" cx="252" cy="180" r="21"/>
        <circle className="cls-1" cx="324" cy="180" r="21"/>
        <circle className="cls-1" cx="396" cy="180" r="21"/>
        <circle className="cls-1" cx="468" cy="180" r="21"/>
        <circle className="cls-1" cx="540" cy="180" r="21"/>
        <circle className="cls-1" cx="180" cy="252" r="21"/>
        <circle className="cls-1" cx="252" cy="252" r="21"/>
        <circle className="cls-1" cx="324" cy="252" r="21"/>
        <circle className="cls-1" cx="396" cy="252" r="21"/>
        <circle className="cls-1" cx="468" cy="252" r="21"/>
        <circle className="cls-1" cx="540" cy="252" r="21"/>
        <circle className="cls-1" cx="180" cy="324" r="21"/>
        <circle className="cls-1" cx="252" cy="324" r="21"/>
        <circle className="cls-1" cx="324" cy="324" r="21"/>
        <circle className="cls-1" cx="396" cy="324" r="21"/>
        <circle className="cls-1" cx="468" cy="324" r="21"/>
        <circle className="cls-1" cx="540" cy="324" r="21"/>
        <circle className="cls-1" cx="180" cy="396" r="21"/>
        <circle className="cls-1" cx="252" cy="396" r="21"/>
        <circle className="cls-1" cx="324" cy="396" r="21"/>
        <circle className="cls-1" cx="396" cy="396" r="21"/>
        <circle className="cls-1" cx="468" cy="396" r="21"/>
        <circle className="cls-1" cx="540" cy="396" r="21"/>
        <circle className="cls-1" cx="180" cy="468" r="21"/>
        <circle className="cls-1" cx="252" cy="468" r="21"/>
        <circle className="cls-1" cx="324" cy="468" r="21"/>
        <circle className="cls-1" cx="396" cy="468" r="21"/>
        <circle className="cls-1" cx="468" cy="468" r="21"/>
        <circle className="cls-1" cx="540" cy="468" r="21"/>
        <circle className="cls-1" cx="180" cy="540" r="21"/>
        <circle className="cls-1" cx="252" cy="540" r="21"/>
        <circle className="cls-1" cx="324" cy="540" r="21"/>
        <circle className="cls-1" cx="396" cy="540" r="21"/>
        <circle className="cls-1" cx="468" cy="540" r="21"/>
        <circle className="cls-1" cx="540" cy="540" r="21"/>
        <circle className="cls-1" cx="180" cy="612" r="21"/>
        <circle className="cls-1" cx="252" cy="612" r="21"/>
        <circle className="cls-1" cx="324" cy="612" r="21"/>
        <circle className="cls-1" cx="396" cy="612" r="21"/>
        <circle className="cls-1" cx="468" cy="612" r="21"/>
        <circle className="cls-1" cx="540" cy="612" r="21"/>
        <circle className="cls-1" cx="180" cy="684" r="21"/>
        <circle className="cls-1" cx="252" cy="684" r="21"/>
        <circle className="cls-1" cx="324" cy="684" r="21"/>
        <circle className="cls-1" cx="396" cy="684" r="21"/>
        <circle className="cls-1" cx="468" cy="684" r="21"/>
        <circle className="cls-1" cx="540" cy="684" r="21"/>
        <circle className="cls-1" cx="612" cy="180" r="21"/>
        <circle className="cls-1" cx="612" cy="684" r="21"/>
      </g>
      <g id="letterforms">
        <path className="cls-2"
          d="M939.61,543.73h45.91c3.37-7.99,6.37-16.16,9.02-24.5h-54.93v24.5ZM1006.21,464.07h-66.31v23.9h62.64c1.55-7.86,2.77-15.83,3.67-23.9M682.42,491.65c3.3-3.92,4.95-8.2,4.95-12.85,0-4.98-1.8-9.24-5.4-12.8-3.6-3.55-7.6-5.33-12-5.33h-22.3v36.85h22.3c5,0,9.15-1.96,12.45-5.88M875.51,576h-38.9v-57.76h-49.9v57.76h-38.9v-144h38.9v54.57h49.9v-54.57h38.9v144ZM703.57,520.23l25.4,55.77h-39.9l-21-49.59h-20.4v49.59h-38.9v-144h65.6c12.53,0,22.62,2.06,30.25,6.17,7.63,4.12,13.18,9.68,16.65,16.68,3.46,7.01,5.2,14.86,5.2,23.55,0,11.02-2.13,19.72-6.4,26.09-4.27,6.37-9.77,11.62-16.5,15.74M900.71,432h107.29c0-159.06-128.94-288-288-288s-288,128.94-288,288,128.94,288,288,288c106.6,0,199.66-57.92,249.46-144h-68.75v-144Z"/>
        <g>
          <path className="cls-2"
            d="M1143.42,577.99c-13.47,0-25.16-3.05-35.07-9.15-9.92-6.1-17.63-14.66-23.13-25.67-5.51-11.01-8.26-23.88-8.26-38.61s2.89-28.26,8.66-39.4c5.77-11.14,13.8-19.77,24.08-25.87,10.28-6.1,22.19-9.15,35.72-9.15,9.09,0,17.33,1.43,24.73,4.28,7.4,2.85,13.75,6.7,19.05,11.54,5.31,4.84,9.43,10.32,12.39,16.42,2.95,6.1,4.53,12.44,4.73,19h-35.52c-1.33-6.37-4.2-11.79-8.61-16.27-4.41-4.48-10.27-6.72-17.56-6.72-5.64,0-10.76,1.46-15.37,4.38-4.61,2.92-8.31,7.68-11.09,14.28-2.79,6.6-4.18,15.44-4.18,26.52,0,9.15.95,16.65,2.84,22.49,1.89,5.84,4.39,10.38,7.51,13.63,3.12,3.25,6.57,5.52,10.35,6.82,3.78,1.29,7.56,1.94,11.34,1.94,4.64,0,8.71-.95,12.19-2.84,3.48-1.89,6.27-4.24,8.36-7.06,2.09-2.82,3.43-5.62,4.03-8.41l1.49-6.97h-27.46v-23.08l63.08.3v75.62h-22.69v-17.31c-2.45,2.85-5.41,5.77-8.86,8.76-3.45,2.98-7.81,5.49-13.08,7.51s-11.83,3.04-19.65,3.04Z"/>
          <path className="cls-2"
            d="M1268.49,577.99c-8.56,0-15.27-.95-20.15-2.84-4.88-1.89-8.44-4.39-10.7-7.51-2.26-3.12-3.67-6.57-4.23-10.35-.56-3.78-.85-7.53-.85-11.24v-117.91h33.13v113.53c0,3.12.73,5.82,2.19,8.11,1.46,2.29,4.61,3.73,9.45,4.33l4.48.3v20.8c-2.12.8-4.33,1.46-6.62,1.99-2.29.53-4.53.8-6.72.8Z"/>
          <path className="cls-2"
            d="M1340.33,577.99c-10.35,0-19.34-2.21-26.96-6.62-7.63-4.41-13.53-10.56-17.71-18.46-4.18-7.89-6.27-17.15-6.27-27.76s2.14-20.18,6.42-28.31c4.28-8.12,10.25-14.44,17.91-18.96,7.66-4.51,16.6-6.77,26.82-6.77s19.42,2.27,27.01,6.82c7.59,4.54,13.47,10.88,17.61,19,4.14,8.13,6.22,17.53,6.22,28.21s-2.06,19.4-6.17,27.36c-4.11,7.96-9.98,14.2-17.61,18.71-7.63,4.51-16.72,6.77-27.26,6.77ZM1340.53,553.12c4.58,0,8.16-1.44,10.75-4.33,2.59-2.89,4.41-6.57,5.47-11.04,1.06-4.48,1.59-9.04,1.59-13.68s-.5-9.04-1.49-13.38c-.99-4.34-2.79-7.94-5.37-10.8-2.59-2.85-6.24-4.28-10.94-4.28s-8.38,1.38-11,4.13c-2.62,2.75-4.46,6.3-5.52,10.65-1.06,4.35-1.59,8.91-1.59,13.68,0,4.51.5,9,1.49,13.48.99,4.48,2.8,8.19,5.42,11.14,2.62,2.95,6.35,4.43,11.19,4.43Z"/>
          <path className="cls-2"
            d="M1469.48,577.99c-9.22,0-16.33-2.24-21.34-6.72-5.01-4.48-8.54-9.93-10.6-16.37l-3.98,21.09h-24.18v-147.86h33.93v57.81c2.85-4.84,6.96-8.52,12.34-11.04,5.37-2.52,11.18-3.78,17.41-3.78,12.67,0,22.59,4.68,29.75,14.03,7.16,9.35,10.75,22.29,10.75,38.81s-3.77,29.4-11.29,39.25c-7.53,9.85-18.46,14.78-32.79,14.78ZM1461.72,554.01c5.37,0,9.87-2.27,13.48-6.82,3.62-4.54,5.42-12.35,5.42-23.43,0-10.15-1.78-17.43-5.32-21.84-3.55-4.41-8.11-6.62-13.68-6.62s-10.33,2.27-13.48,6.82c-3.15,4.54-4.76,11.76-4.83,21.64,0,11.14,1.59,18.97,4.78,23.48,3.18,4.51,7.73,6.77,13.63,6.77Z"/>
          <path className="cls-2"
            d="M1562.51,577.99c-6.57,0-12.5-1.24-17.81-3.73-5.31-2.49-9.52-6.12-12.64-10.9-3.12-4.78-4.68-10.61-4.68-17.51,0-11.21,4.31-19.83,12.93-25.87,8.62-6.04,22.15-9.09,40.6-9.15l10.45-.1v-5.27c0-8.16-4.71-12.17-14.13-12.04-3.52.13-7.01.98-10.5,2.54-3.48,1.56-5.95,4.4-7.41,8.51h-27.76c.33-7.76,2.6-14.1,6.82-19,4.21-4.91,9.82-8.52,16.82-10.85,7-2.32,14.84-3.48,23.53-3.48,11.21,0,20.11,1.33,26.72,3.98,6.6,2.65,11.31,6.4,14.13,11.24,2.82,4.84,4.23,10.51,4.23,17.01v72.63h-28.16l-2.89-17.01c-3.32,7.23-7.41,12.21-12.29,14.92-4.88,2.72-10.86,4.08-17.96,4.08ZM1574.16,556.2c4.51,0,8.49-1.49,11.94-4.48,3.45-2.98,5.21-6.43,5.27-10.35v-14.03l-8.36.1c-3.45,0-7,.46-10.65,1.39-3.65.93-6.75,2.47-9.3,4.63-2.55,2.16-3.83,5.12-3.83,8.91,0,4.38,1.48,7.78,4.43,10.2,2.95,2.42,6.45,3.63,10.5,3.63Z"/>
          <path className="cls-2"
            d="M1681.02,577.99c-8.56,0-15.27-.95-20.15-2.84-4.88-1.89-8.44-4.39-10.7-7.51-2.26-3.12-3.67-6.57-4.23-10.35-.56-3.78-.85-7.53-.85-11.24v-117.91h33.13v113.53c0,3.12.73,5.82,2.19,8.11,1.46,2.29,4.61,3.73,9.45,4.33l4.48.3v20.8c-2.12.8-4.33,1.46-6.62,1.99-2.29.53-4.53.8-6.72.8Z"/>
        </g>
      </g>
    </svg>
  )
}

export default RheLogoVector;
