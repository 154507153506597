import {Routes as RouterRoutes, Route} from 'react-router-dom';
import {AuthenticationGuard} from './components/AuthenticationGuard';
import {AuthenticationContextProvider} from './login/AuthenticationContextProvider';
import {AdminHome} from './pages/AdminHome';
import {LoginPage} from './pages/LoginPage';
import {NotFoundPage} from './pages/NotFoundPage';
import {ServerErrorPage} from './pages/ServerError';
import {SearchPage} from './pages/SearchPage';
import {SearchContextProvider} from './search/SearchContextProvider';
import {CreateLicenceContextProvider} from './create_licence/context/CreateLicenceContextProvider';
import {CreateLicencePage} from './pages/CreateLicencePage';
import {ListOrganisationsPage} from './pages/ListOrganisationsPage';
import {BulkUploadPage} from './pages/BulkUploadPage';
import MainLayout from './components/MainLayout';
import MarketingPage from './pages/MarketingPage';
import {EditLicencePage} from './pages/EditLicencePage';

export const Routes = () => <RouterRoutes>
  <Route element={<SearchContextProvider />}>
    <Route element={<AuthenticationContextProvider />}>
      <Route element={<MainLayout/>}>
        <Route path={'/'} element={<MarketingPage />} />
        <Route path={'/login'} element={<LoginPage />} />
        <Route path={'/search'} element={<SearchPage />} />
        <Route element={<AuthenticationGuard />}>
          <Route path={'/admin'} element={<AdminHome />} />
          <Route element={<CreateLicenceContextProvider />}>
            <Route path={'/create-licence'} element={<CreateLicencePage />} />
          </Route>
          <Route path={'/edit-licence'} element={<EditLicencePage />} />
          <Route path={'/authorities'} element={<ListOrganisationsPage />} />
          <Route path={'/bulk-upload'} element={<BulkUploadPage />} />
        </Route>
        <Route path={'*'} element={<NotFoundPage />} />
        <Route path={'/500'} element={<ServerErrorPage />} />
      </Route>
    </Route>
  </Route>
</RouterRoutes>;
