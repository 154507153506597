import {useTranslation} from 'react-i18next';
import {ApplicationError} from './ApplicationError';
import {errorMessages} from './errorMessages';

export const useApplicationErrorMessage = () => {
  const {t} = useTranslation();

  return {
    get: (error: ApplicationError) => t(errorMessages[error.code], {
      supportEmail: process.env.SUPPORT_EMAIL,
    })
  };
}