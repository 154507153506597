import {useOrganisation} from './useOrganisation';
import {PropsWithChildren} from 'react';
import {Alert, AlertDescription, AlertIcon} from '@chakra-ui/react';
import {Trans} from 'react-i18next';

interface OrganisationGuardProps {
  organisationId: string;
}

export const OrganisationGuard = (props: PropsWithChildren<OrganisationGuardProps>) => {
  const organisation = useOrganisation();

  if (!organisation || organisation.id !== props.organisationId) {
    return <Alert status='error' data-testid={'organisation-guard__alert'}>
      <AlertIcon />
      <AlertDescription>
        <Trans>You do not have permission to perform this action, please contact your administrator.</Trans>
      </AlertDescription>
    </Alert>;
  }

  return <>{props.children}</>;
};