import {has} from 'ramda';
import {ApplicationError} from '../../error/ApplicationError';
import {ValidationError} from './ValidationError';

export const extractValidationError = (error?: ApplicationError): ValidationError | undefined => {
  if (has('validationErrors', error)) {
    return error as ValidationError;
  }

  return undefined;
}