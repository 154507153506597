import {Td, Tr} from '@chakra-ui/react';
import {Organisation} from '../api/client.schemas';

interface OrganisationRowProps {
  organisation: Organisation;
}

export const OrganisationRow = (props: OrganisationRowProps) => {
  return <Tr>
    <Td>{props.organisation.name}</Td>
  </Tr>;
};