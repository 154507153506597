import {Spinner} from '@chakra-ui/react';
import {Trans} from 'react-i18next';

import {SPSelect} from './SPSelect';

import {SpecificInputType} from '../../types/validation/Input';
import {useGetLicenceTypes} from '../../api/client';
import {useLanguage} from '../../hooks/useLanguage';

export const SelectLicenceType = ({id = 'licenceType',required = false, onChanged, enabled=true, disabled=false}:SpecificInputType) => {
  const language = useLanguage();
  const {data: licenceTypes, isLoading} = useGetLicenceTypes({
    language,
  }, {
    query: {
      enabled,
    }
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (!licenceTypes) {
    return null;
  }

  return (
    <SPSelect
      id={id}
      label="Licence type"
      required={required}
      placeholder="Select a licence"
      onChanged={onChanged}
      disabled={disabled}
      errors={required ? ({
        required: <Trans>Please select a licence type</Trans>
      }) : ({})}
    >
      {licenceTypes.results.map(({licence_type_id, name}, index) => (
        <option key={index} value={licence_type_id}>{name}</option>
      ))}
    </SPSelect>
  )
}