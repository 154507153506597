import {Center} from '@chakra-ui/react';
import {CreateLicenceStep} from '../create_licence/CreateLicenceStep';
import {FormProvider, useForm} from 'react-hook-form';
import {CreateLicenceType} from '../types';
import {Spacing08} from '@RHEnvironmental/pattern-library-tokens';
import {px} from '../theme/px';
import {DEFAULT_VALUES} from '../create_licence/FormValues';
import {PermissionsGuard} from '../admin/permissions/PermissionsGuard';
import {PERMISSION_LICENCE_CREATE} from '../admin/permissions/Permissions';

export const CreateLicencePage = () => {
  const methods = useForm<CreateLicenceType>({
    defaultValues: DEFAULT_VALUES,
  });

  return (
    <PermissionsGuard permission={PERMISSION_LICENCE_CREATE}>
      <FormProvider {...methods}>
        <Center mt={px(Spacing08)}>
          <CreateLicenceStep />
        </Center>
      </FormProvider>
    </PermissionsGuard>
  )
}