import {createContext} from 'react';
import {User} from '../types/User';

export interface AuthenticationContextData {
  token: string;
  setToken: (token: string) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (loggedIn: boolean) => void;
  user: User | undefined;
  setUser: (user: User | undefined) => void;
}

export const AuthenticationContext = createContext<AuthenticationContextData>({
  token: '',
  setToken: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  user: undefined,
  setUser: () => {},
});