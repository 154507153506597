import {PractitionerLicence} from '../../types';
import {CardInfo} from './CardInfo';
import {LicenceCard} from './LicenceCard';
import {strFromAddress} from './dataHandling';

export const PractitionerLicenceCard = ({licence}:{licence: PractitionerLicence}) => {
  const {
    practitioner_name,
    addresses,
  } = licence;

  return (
    <LicenceCard licence={licence} header={practitioner_name}>
      <CardInfo key={0} title="Licence number" value={licence.licence_number} />
      {addresses.map((address, index) => (
        <CardInfo key={index} title="Associated business" subTitle={address.associated_business_name} value={strFromAddress(address, false)} />
      ))}
    </LicenceCard>
  );
}