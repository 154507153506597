import {usePermissions} from './usePermissions';
import {PropsWithChildren} from 'react';
import {Alert, AlertDescription, AlertIcon} from '@chakra-ui/react';
import {Trans} from 'react-i18next';
import {Permission} from './Permissions';

type PermissionsGuardProps = PropsWithChildren<{ permission: Permission }>;

export const PermissionsGuard = (props: PermissionsGuardProps) => {
  const {hasPermission} = usePermissions();

  if (!hasPermission(props.permission)) {
    return <Alert status='error' data-testid={'permissions-guard__alert'}>
      <AlertIcon />
      <AlertDescription>
        <Trans>You do not have permission to perform this action, please contact your administrator.</Trans>
      </AlertDescription>
    </Alert>;
  }

  return <>{props.children}</>;
};